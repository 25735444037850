import { Tooltip } from "@mui/material";
import _ from 'lodash';
import moment from "moment-timezone";

export const generateRandomString = (length) => {
  var charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';
  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * charset.length);
    result += charset.substring(randomIndex, randomIndex + 1);
  }
  return result;
}

async function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return hash;
}

function base64URLEncode(str) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export const generateCode = async (codeVerifierString) => {
  const verifier = codeVerifierString && codeVerifierString != "" ? codeVerifierString : generateRandomString(128);
  const hashedVerifier = await sha256(verifier);
  const base64EncodedHash = base64URLEncode(hashedVerifier);
  var data = {
    verifier: verifier,
    base64EncodedHash: base64EncodedHash
  }
  return data;
};

export function truncateString(str, maxLength) {
  if (str?.length <= maxLength) {
    return str;
  }
  return (
    <Tooltip title={str}>
      {str?.slice(0, maxLength - 3) + '...'}
    </Tooltip>
  )
}

export function sortMethod(list, value) {
  return _.orderBy(list, [item => {
    const val = _.get(item, value);

    if (_.isNumber(val)) {
      return val;
    }

    return _.toString(val);
  }], ['asc']);  // 'asc' for ascending, 'desc' for descending
}

export function getClientDateTime(time) {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment.utc(time).tz(userTimeZone);
}

export const onlyLettersAndSpaces = (value) => /^[a-zA-Z]+[a-zA-Z0-9\s]*$/.test(value); 
// Checks if the string starts with one or more letters and can be followed by letters, numbers, and spaces (but no special characters or leading spaces).
