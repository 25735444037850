import React, { Fragment, useState } from "react";
import { AppEnum } from "../../constants/app-enum";
import { SignupModel } from "./signup-state";
import * as loginService from '../../services/login-signup.service';
import { useLocation, useNavigate } from "react-router-dom";
import { Alpha, RegexEmail, RegexForAcceptAllExceptWhitespace } from "../../constants/regular-expression";
import { LoginSignUp } from "../../components/login-signup/login-signup";
import { setToken } from "../../shared/local-storage-handler/local-storage-handler";
import { setSessionStorageItem } from "../../shared/session-storage-handler/session-storage-handler";
import jwtDecode from "jwt-decode";
import { useASContext } from "../context-api/as-context";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";

const SignupController = (props) => {

    // signup state
    const [signupModel, setSignupModel] = useState(new SignupModel());
    const isGoogleSheetPluginActive = useSelector((state) => state.pluginState?.isGoogleSheetPluginActive);
    const navigate = useNavigate();
    const asContext = useASContext();


    // it handles fullname state whenever fullname textbox is changed
    const onChangeFullnameHandler = (event) => {

        setSignupModel((prevState) => {
            return { ...prevState, fullName: event.target.value, }
        });

        if (!signupModel.isFullnameValid)
            onBlurFullnameHandler(event)

        if (signupModel.errorMessage)
            clearErrorMessage();
    }

    // when user looses focus it checks if fullname is valid or not
    const onBlurFullnameHandler = (event) => {

        if (event.target.value) {
            let isFullnameValid = Alpha.test(event.target.value);

            if (isFullnameValid)
                setSignupModel((prevState) => { return { ...prevState, isFullnameValid: true, } });
            else
                setSignupModel((prevState) => { return { ...prevState, isFullnameValid: false, } });
        }
    }

    // it handles email state whenever email textbox is changed( during signup)
    const onChangeEmailHandler = (event) => {

        setSignupModel((prevState) => {
            return { ...prevState, email: event.target.value, }
        });

        if (!signupModel.isEmailValid)
            onBlurEmailHandler(event)

        if (signupModel.errorMessage)
            clearErrorMessage();
    }

    // when user looses focus it checks if email is valid or not
    const onBlurEmailHandler = (event) => {

        if (event.target.value) {
            let isEmailValid = RegexEmail.test(event.target.value);

            if (isEmailValid)
                setSignupModel((prevState) => { return { ...prevState, isEmailValid: true, } });
            else
                setSignupModel((prevState) => { return { ...prevState, isEmailValid: false, } });
        }
    }

    // it handles business name state whenever business name textbox is changed
    const onChangeBusinessHandler = (event) => {

        setSignupModel((prevState) => {
            return { ...prevState, businessName: event.target.value, }
        });

        if (!signupModel.isBusinessNameValid)
            onBlurBusinessNameHandler(event)


        if (signupModel.errorMessage)
            clearErrorMessage();
    }

    // when user looses focus it checks if business name is valid or not
    const onBlurBusinessNameHandler = (event) => {

        if (event.target.value) {
            let isBusinessNameValid = RegexForAcceptAllExceptWhitespace.test(event.target.value);

            if (isBusinessNameValid)
                setSignupModel((prevState) => { return { ...prevState, isBusinessNameValid: true, } });
            else
                setSignupModel((prevState) => { return { ...prevState, isBusinessNameValid: false, } });
        }
    }

    // it handles business name state whenever business name textbox is changed
    const onChangePasswordHandler = (event) => {

        setSignupModel((prevState) => {
            return { ...prevState, password: event.target.value, }
        });

        if (signupModel.confirmPassword && !signupModel.isPasswordValidated &&
            event.target.value === signupModel.confirmPassword) {
            setPasswordValidate();
        }

        if (signupModel.errorMessage)
            clearErrorMessage();
    }

    // it handles confirm password state whenever confirm password textbox is changed
    const onChangeConfirmPassHandler = (event) => {

        setSignupModel((prevState) => {
            return { ...prevState, confirmPassword: event.target.value, }
        });

        if (!signupModel.isPasswordValidated && event.target.value === signupModel.password) {
            setPasswordValidate();
        }
    }

    // it sets the state true if password is validates
    const setPasswordValidate = () => {

        setSignupModel((prevState) => {
            return { ...prevState, isPasswordValidated: true, }
        });
    }

    // it handles on business account checkboxes
    const onCheckBusinessAccountHandler = (event) => {

        let isIndividual = event.target.value;

        if (isIndividual.length > 0)
            isIndividual = JSON.parse((isIndividual).trim().toLowerCase());

        setSignupModel((prevState) => {
            return { ...prevState, isIndividual: isIndividual, businessName: '' }
        });
    }

    // it clears the error message
    const clearErrorMessage = () => {
        setSignupModel((prevState) => { return { ...prevState, errorMessage: null, } });
    }


    // it sets the error message state
    const setErrorMessage = (message) => {

        setSignupModel((prevState) => {
            return {
                ...prevState,
                errorMessage: message,
            }
        });
    }

    // it register's user, i.e. create user
    const createUser = () => {

        if (signupModel.fullName && signupModel.isFullnameValid && signupModel.email && signupModel.isEmailValid
            && signupModel.password && signupModel.confirmPassword && signupModel.isPasswordValidated
            && (signupModel.isIndividual ? true : (signupModel.businessName && signupModel.isBusinessNameValid))) {

            if (signupModel.errorMessage)
                clearErrorMessage();

            let userInfo = {
                fullName: signupModel.fullName,
                email: signupModel.email,
                password: signupModel.password,
                isIndividual: signupModel.isIndividual,
                businessName: signupModel.isIndividual ? '' : signupModel.businessName,
                userRoleId: AppEnum.UserRoleIds.UserRoleId,
            };

            loginService.signUp(userInfo)
                .then((response) => {

                    if (response?.hasError){
                        setErrorMessage(response?.errorMessage);
                        toast.error(response?.errorMessage);
                    }
                    else if (response?.data?.item1?.hasError){
                        setErrorMessage(response?.data?.item1?.validationErrors[0]?.errorMessage);
                        toast.error(response?.data?.item1?.validationErrors[0]?.errorMessage);
                    }
                    else if (response?.data?.item2?.access_token) {
                        setToken("token", response?.data?.item2?.access_token);
                        setToken("refresh_token", response?.data?.item2?.refresh_token);
                        let decodedToken = jwtDecode(response?.data?.item2?.access_token);
                        if (decodedToken?.ASTenantUId) {
                            setToken("ASTenantId", decodedToken?.ASTenantUId)
                            setSessionStorageItem("ASTenantId", decodedToken?.ASTenantUId)
                        }
                        asContext?.tenants?.setSelectedTenant(null);
                        navigate(isGoogleSheetPluginActive ? '/plugin': '/Checkout');
                    }
                    // navigate("/"); 

                })
                .catch((err) => setErrorMessage(err.validationErrors));
        }
        else if (signupModel.fullName && signupModel.isFullnameValid && signupModel.email && signupModel.isEmailValid
            && signupModel.password && signupModel.confirmPassword && !signupModel.isPasswordValidated
            && (signupModel.isIndividual ? true : (signupModel.businessName && signupModel.isBusinessNameValid))) {
                return;
        }
        else {
            setErrorMessage('Please fill all the required fields to proceed');
        }

    }

    // it validates the password and confirm password, checks both are same or not
    const validatePassAndConfirmPass = () => {

        if (signupModel.password && signupModel.confirmPassword) {

            if (signupModel.password === signupModel.confirmPassword) {

                setSignupModel((prevState) => {
                    return { ...prevState, isPasswordValidated: true, }
                });
            }
            else {

                setSignupModel((prevState) => {
                    return { ...prevState, isPasswordValidated: false, }
                });
            }
        }

    }

    // it resets the state to initial value if view is changed
    const resetFormState = () => {

        setSignupModel(new SignupModel());
    }

    // it displays the entered password 
    const showHidePassword = () => {

        setSignupModel((prevState) => {
            return {
                ...prevState,
                isPasswordVisible: !prevState.isPasswordVisible,
            }
        });
    }

    // it displays the entered confirm password 
    const showHideConfirmPassword = () => {

        setSignupModel((prevState) => {
            return {
                ...prevState,
                isConfirmPasswordVisible: !prevState.isConfirmPasswordVisible,
            }
        });
    }

    // dumb component
    return (
        <Fragment>

            <LoginSignUp
                pFlag={props.pFlag}
                signupModel={signupModel}
                onChangeFullnameHandler={onChangeFullnameHandler}
                onChangeEmailHandler={onChangeEmailHandler}
                onChangeBusinessHandler={onChangeBusinessHandler}
                onChangePasswordHandler={onChangePasswordHandler}
                onChangeConfirmPassHandler={onChangeConfirmPassHandler}
                onBlurEmailHandler={onBlurEmailHandler}
                createUser={createUser}
                onCheckBusinessAccountHandler={onCheckBusinessAccountHandler}
                validatePassAndConfirmPass={validatePassAndConfirmPass}
                onBlurFullnameHandler={onBlurFullnameHandler}
                onBlurBusinessNameHandler={onBlurBusinessNameHandler}
                resetFormState={resetFormState}
                showHidePassword={showHidePassword}
                showHideConfirmPassword={showHideConfirmPassword}
            />

        </Fragment>
    )
}

export default SignupController;