import { Email } from "@mui/icons-material";
import { axios } from "../core/axios.interceptor";

const user_sheet_link = "user-sheet-link";

export async function userSheetLink(sheetModel) {
    const response = await axios.post(`${user_sheet_link}`, sheetModel);
    return response;
}

export async function getUserSheet(collectionUId) {
    const response = await axios.get(`${user_sheet_link}?collectionUId=${collectionUId}`);
    return response;
}

export async function getUserSheetBySheetId(sheetId) {
    const response = await axios.get(`${user_sheet_link}/sheet-id?sheetId=${sheetId}`);
    return response;
}

export async function getUserSheetByDocumentLink(documentLink) {
    const response = await axios.get(`${user_sheet_link}/document_link?doumentUrl=${documentLink}`);
    return response;
}

// //resend invite
// export async function resendInvite(email) {
//     const response = await axios.put(`/${invitedUserbaseURL}?email=${email}`);
//     return response;
// }
// //cancel invite
// export async function cancelInvite(uId) {
//     const response = await axios.delete(`/${invitedUserbaseURL}/${uId}`);
//     return response;
// }
// // api for getting user list
// export async function getAllUsers() {
//     const response = await axios.get(`users`);
//     return response;
// }