import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { ASStepper } from "../../../shared/stepper/as-stepper";
import "./add-new-data-collection.scss";
import BasicInformationController from "./basic-information/basic-information-controller";
import CollectionTypeAndObjectController from "./collection-type-and-object/collection-type-and-object-controller";
import CollectionParametersController from "./collection-parameters/collection-parameters-controller";
import SelectColumnsController from "./select-columns/select-columns-controller";
import TestDataController from "./test-data/test-data-controller";
import { CollectionFilterColumn, CollectionParametersState, DataCollectionModal, DataCollectionState } from "./new-data-collection-state";
import * as connectionService from '../../../services/data-source.service';
import * as collectionService from '../../../services/data-collection.service';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import themeColor from "./../../../styles/_exports.module.scss";
import SendBodyAsController from "./send-body-as/send-body-as";
import { RestServiceConstants } from "../../../constants/rest-service-constant";
import DataTransformationScriptController from "./data-transformation-script/data-transformation-script";
import DataAfterTransformationController from "./data-after-transformation/data-after-transformation";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SelectConnectionController from "./select-connection/select-connection-controller";
import SelectObjectController from "./select-object/select-object-controller";
import ColumnSelectionController from "../column-selection/column-selection";
import { isNullOrEmpty } from "../../../services/data-source-validation";
import { CollectionStepLabelConstant } from "../../../constants/as-stepper-step-label";
import { NewUserModel } from "../../connections/variables/variables-Popup";
import { DynamicList } from "../../connections/variables/variables-Popup";
import { UpdateUserModel } from "../../connections/variables/variables-Popup";
import CollectionFilterColumnController from "./collection-filter-column/collection-filter-column-controller";
import {getTemplateCompany} from '../../../services/variable-service'
import { useASContext } from "../../context-api/as-context";
import MuiDialog from "../../../shared/mui-dialog/mui-dialog";
import { AppEnum } from "../../../constants/app-enum";
import { authorizeAgain, getToken } from "../../../services/authorize-again-service";
import { testData } from "../../../services/test-data.service";
import { getCollectionPermissionByCollectionUId } from "../../../services/user-service";
import { sortMethod } from "../../../shared/code-challanges/code-challanges";
import { checkOtherParams, filterCollectionParams } from "../DataVisualizer/data-visualizer-controller";
import { getTemplateCollectionParameters, updateParameterValues } from "../utils/template-collection-parameter";
import { updateColumnDataTypes } from "./select-columns/datatypes-equivalent-map";

export const tableBorderRight = { borderRight: themeColor.borderBottom };
export const keyWidth = { width: "200px", borderRight: themeColor.borderBottom };
export const valueWidth = { width: "200px", borderRight: themeColor.borderBottom };
export const parameterTypeWidth = { width: "200px", borderRight: themeColor.borderBottom };
export const valueTypeWidth = { width: "200px", borderRight: themeColor.borderBottom };
export const variableNameWidth = { width: "190px", borderRight: themeColor.borderBottom };

export const findDisplayFormat = (dataTypeName, state) => {
    if (state?.displayFormatList.length > 0) {
        if (dataTypeName?.toLowerCase() == AppEnum.OtherDataBaseType.Number){
            dataTypeName = "Int64";
        }
        return state?.displayFormatList?.find(item => item.dataBaseDataType?.toLowerCase() == dataTypeName?.toLowerCase());
    }
};

export function AddNewDataCollection() {

    const [state, setState] = useState(new DataCollectionState());
    const [parametersState, setParametersState] = useState(new CollectionParametersState());
    const [extractedParamters, setExtractedParamters] = useState([]);
    const [dataCollectionModal, setDataCollectionModal] = useState(new DataCollectionModal());
    const [collectionFilterColumnState, setCollectionFilterColumnState] = useState(new CollectionFilterColumn());
    const [activeStep, setActiveStep] = useState(0);
    const [selectedConnection, setSelectedConnection] = useState({});
    const [authCode, setAuthCode] = useState();
    const [isAuthorizeAgain, setIsAuthorizeAgain] = useState(false);
    const [activeStatus, setActiveStatus] = useState(false);
    const { collectionUId: collectionUId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const prevStepRef = useRef(null);
    const [isTemplateCollection, setIsTemplateCollection] = useState(false);
    const [initialCollectionName, setInitialCollectionName] = useState("");
    const [isSavingCollection, setIsSavingCollection] = useState(false);
    const [stepperViewFromConnection,setStepperViewFromConnection] = useState(false);
    const [isTestedData,setIsTestedData] = useState(false);
    const [isChangedDataParams,setIsChangedDataParams] = useState(false);
    const [isShowHeader,setIsShowHeader] = useState(false);
    const [isCloudLoading,setIsCloudLoading] = useState(false);
    const [isDynamicListValues, setIsDynamicListValues] = useState(
        new DynamicList()
      );
    const [variableUId,setVariableUId] = useState("");
    const [isVariableUsedInCollection,setIsVariableUsedInCollection] = useState(false);
    const [isTemplateCompany, setIsTemplateCompany] = useState(false);
    const [isManualColumnValid, setIsManualColumnValid] = useState(true);
    const [prevCloudFilterationData, setPrevCloudFilterationData] = useState({
        prevFilterData: [],
        prevSortData: [],
        prevLimitData: null,
        templateCollectionId: null
    });
    const [isCloudFilterationData, setIsCloudFilterationData] = useState(false);
    const [openConnectionPopup, setOpenConnectionPopup] = useState(false);
    const [stateConnectionParameter, setStateConnectionParameter] = useState(false);
    const [isStepDataChanged, setIsStepDataChanged] = useState(false); // if any step data gets updated.
    const [showTestCollectionPopup, setShowTestCollectionPopup] = useState(false); 
    const [isTestedAfterSaveAppears,setIsTestedAfterSaveAppears] = useState(false);
    const asContext = useASContext();
    const [isSearchOptionsAvailable, setIsSearchOptionsAvailable] = useState(false);
    const [searchOptionsParameters, setSearchOptionsParameters] = useState([]);
    const [maunalColumns,setManualColumns] = useState([]);
    const [columnOverriden,setColumnOverriden] = useState(false);

    useEffect(()=>{
          if(!(asContext.subscriptionValidation.isSubscriptionExist)){
              navigate("/data-collections");
          }
      },[asContext.subscriptionValidation.isSubscriptionExist]);

    useEffect(() =>{
        if(location.pathname.includes(AppEnum.WebPages.UpdateCollection) && asContext?.tenants?.selectedTenant?.UserRoleId === AppEnum.UserRoleIds.CollectionAdmin){
           
            getCollectionPermissionByCollectionUId(collectionUId).then((response)=>{
              if(response){
                if(!response.data){
                   navigate("/unauthorized-resource", { state: { from: location.pathname } });
                }
              }
            });
    
        }
    },[asContext?.tenants?.selectedTenant])

    useEffect(() => {

        getConnectionList();
        getCategoryList();
        getDisplayFormatList();
        if (collectionUId) {
            setState((prevState) => { return { ...prevState, uId: collectionUId } });
            getCollectionDetails();
        }
    }, []);
    
    useEffect(() => {
        if (collectionUId && !state?.templateCollectionId && !isTestedData && (state?.testCollectionError || isAuthorizeAgain)) {
            let jumpToStep = state?.sysCollectionTypeId ===  AppEnum.SysCollectionTypeId.REST ? 4 : 3;// index values for test-data steps

            if (activeStep != jumpToStep)
                setActiveStep(jumpToStep);
        }
    }, [isAuthorizeAgain, state?.testCollectionError]);

    useEffect(() => {
        if (state.connectionUId && collectionUId) {
            getCurrentConnectionDetails(state.connectionUId);
        }
    }, [state.connectionUId, collectionUId])

    useEffect(() => {
        let isOnlyForHeader = isShowHeader ? true : state.testedDataList?.length > 0
        if (state?.collectionColumns.length > 0  && state?.columnSchemaList?.length == 0 && state?.displayFormatList?.length > 0 && !state?.templateCollectionId && isOnlyForHeader) {

            const hasColumnDataType = state?.columnSchemaList.some(column => column.ColumnDataType && column.ColumnDataType !== null);
            if (hasColumnDataType)
                return;

            const updatedColumns = state?.collectionColumns.map(column => ({
                ...column,
                ColumnDataType: state?.displayFormatList?.find(i => i.sysDataTypeId === column?.dataType)?.dataBaseDataType,
                isSelected: true
            }));
            
            const updatedDataTypeColumnsList = updateColumnDataTypes(state?.displayFormatList, updatedColumns);
            const sortedList = _.orderBy(
                updatedDataTypeColumnsList,
                [(col) => col.displayOrder === 0 ? Number.MAX_SAFE_INTEGER : col.displayOrder],
                ['asc']
            );

            setState(prevState => ({
                ...prevState,
                columnSchemaList: sortedList,
                collectionColumns: sortedList
            }));
            setIsTestedData(true);
        }
    }, [state?.collectionColumns, state?.displayFormatList, state.testedDataList]);

    useEffect(() => {
        async function fetchTemplateCompany() {
            try {
                const response = await getTemplateCompany();
                if (response?.data == true) {
                    setIsTemplateCompany(true);
                }
            } catch (error) {
                console.error("Error fetching template company details:", error);
            }
        }
        fetchTemplateCompany();
    }, []);

    useEffect(() => {
        if (state?.collectionParameters.length > 0 && prevCloudFilterationData?.templateCollectionId === null && collectionUId) {
            var filterParameter = state?.collectionParameters.filter(i => i.parameterName == "Filter");
            var sortParameter = state?.collectionParameters.filter(i => i.parameterName == "Sort");
            var limitParameter = state?.collectionParameters.filter(i => i.parameterName == "Limit");
            if (filterParameter && filterParameter?.length > 0) {
                var filter = JSON.parse(filterParameter[0].defaultValue);
                    setPrevCloudFilterationData(prevState => ({
                        ...prevState,
                        prevFilterData: filter,
                        templateCollectionId: state?.templateCollectionId
                    }));
            }
            if (sortParameter && sortParameter?.length > 0) {
                var sort = JSON.parse(sortParameter[0].defaultValue);
                    setPrevCloudFilterationData(prevState => ({
                        ...prevState,
                        prevSortData: sort,
                        templateCollectionId: state?.templateCollectionId
                    }));
            }
            if (limitParameter && limitParameter?.length > 0) {
                var limit = JSON.parse(limitParameter[0].defaultValue);
                    setPrevCloudFilterationData(prevState => ({
                        ...prevState,
                        prevLimitData: limit,
                        templateCollectionId: state?.templateCollectionId
                    }));
            }
        }
    }, [state?.collectionParameters])

    useEffect(() => {
        if (state?.cloudCollectionObjects.length > 0 && state?.templateCollectionId){
            let object = state?.cloudCollectionObjects?.find(i => i.templateCollectionId == state?.templateCollectionId);
            if (object){
                getTemplateCollectionParameters(object?.templateCollectionUId, setState, setIsSearchOptionsAvailable, setSearchOptionsParameters, setManualColumns, setColumnOverriden);
            }
        }
    }, [state?.cloudCollectionObjects, state?.templateCollectionId])    

    // it fetches collection details by its collectionUId
    const getCollectionDetails = () => {

        collectionService.getCollectionDetails(collectionUId)
            .then((response) => {
                if (response?.data) {
                    let collection = location?.state;
                    let prevCollection = response?.data;
                    setInitialCollectionName(prevCollection.name);

                    prevCollection.connectionUId = collection?.connection?.uId ? collection?.connection?.uId : prevCollection?.connectionUId;
                    prevCollection.categoryUId = collection?.category?.categoryUId ? collection?.category?.categoryUId : prevCollection?.categoryUId;

                    if (
                        prevCollection.isCollectionAsDataSource &&
                        prevCollection?.variable != null
                      ) {
                        setVariableUId(prevCollection?.variable?.uId);
                        setIsVariableUsedInCollection(prevCollection?.variable?.isUsedInCollection);
                        const parsedData = JSON.parse(
                          prevCollection?.variable?.complexTypeValue
                        );
                        setIsDynamicListValues({...parsedData, isDefaultValueCorrect: parsedData?.isDefaultValueCorrect ?? true});
                        
                      } 

                      if(prevCollection?.sourceNamePart2?.length == 0){
                            prevCollection.sourceNamePart2 = prevCollection.name;
                      }
              

                    if (prevCollection) {
                        setState((prevState) => {

                                return {
                                ...prevState,
                                sysCollectionTypeId: prevCollection.sysCollectionTypeId,
                                sysCollectionSubTypeId: prevCollection.sysCollectionSubTypeId,
                                sysConnectionTypeId: prevCollection.sysConnectionTypeId,
                                childGroupName: prevCollection.childGroupName,
                                groupName: prevCollection.groupName,
                                sourceName: prevCollection.sourceName,
                                sourceNamePart2: prevCollection.sourceNamePart2,
                                sourceNamePart4: prevCollection.sourceNamePart4,
                                isCollectionAsDataSource: prevCollection.isCollectionAsDataSource,
                                hasPrintableCollection: prevCollection.hasPrintableCollection,
                                collectionColumns: prevCollection.collectionColumns,
                                collectionParameters: _.orderBy(prevCollection.collectionParameters,['displayOrder', 'id'],['asc', 'asc']),
                                collectionFilterColumns: prevCollection.collectionFilterColumns,
                                name: prevCollection.name,
                                connectionUId: prevCollection.connectionUId,
                                categoryUId: prevCollection.categoryUId,
                                restSendAsCD: prevCollection.restSendAsCD,
                                restDataTransformationScript: prevCollection.restDataTransformationScript,
                                restBody: prevCollection.restBody,
                                restRequestMethod: prevCollection.restRequestMethod,
                                prevCollection: prevCollection,
                                basicInfoError: null,
                                templateCollectionId: prevCollection.templateCollectionId,
                                isFilterSupported: prevCollection.isFilterSupported,
                                isSortSupported: prevCollection.isSortSupported,
                                isPagingSupported: prevCollection.isPagingSupported,
                                areAllFieldsFilterable: prevCollection.areAllFieldsFilterable,
                                areAllFieldsSortable: prevCollection.areAllFieldsSortable,
                                columnApiEndpoint: prevCollection.columnApiEndpoint,
                                variableId: prevCollection.variableId,
                                returnRawData: prevCollection.returnRawData,
                                variable: prevCollection.variable
                            }
                        })
                        if (prevCollection.isTemplateCollection && prevCollection.isInheritCollection) {
                            //setState((prevState) => { return { ...prevState, uId: null, templateCollectionId: prevCollection.id } });
                            setState((prevState) => { return { ...prevState, uId: null } });
                            setIsTemplateCollection(true);
                        }

                        if (prevCollection?.collectionParameters?.length > 0){
                            const hasOtherParams = checkOtherParams(prevCollection?.collectionParameters);
                         
                              if (hasOtherParams){
                                prevCollection["collectionParameters"] = filterCollectionParams(prevCollection?.collectionParameters)
                                let updatedParams = updateParameterValues(prevCollection.collectionParameters);
                                setIsSearchOptionsAvailable(true);
                                setSearchOptionsParameters(updatedParams);
                              }
                              else{
                                setIsSearchOptionsAvailable(false);
                                setSearchOptionsParameters([]);
                              }
                        }
                        else{
                            setIsSearchOptionsAvailable(false);
                            setSearchOptionsParameters([]);
                        }

                    }
                    const baseConnectionTypeId = collection?.connection?.baseConnectionTypeId;
                    const sysConnectionTypeId = collection?.connection?.sysConnectionTypeId ? collection?.connection?.sysConnectionTypeId : prevCollection?.sysConnectionTypeId;
                    const sysCollectionTypeId = collection?.sysCollectionTypeId ? collection?.sysCollectionTypeId : prevCollection?.sysCollectionTypeId;
                    const templateCollectionId = collection?.templateCollectionId ? collection?.templateCollectionId : prevCollection?.templateCollectionId;

                    getCollectionTypeList(sysConnectionTypeId);
                    if (sysCollectionTypeId != 7 && !templateCollectionId){
                        getColumnSchema(prevCollection);
                    }
                    else if (collectionUId && !isTestedData && !templateCollectionId) {
                        getTestedCollection(prevCollection);
                    }
                    setActiveStatus(response.data.isActive);
                }
                else if (response?.hasError)
                    setState((prevState) => { return { ...prevState, basicInfoError: response.errorMessage } });
            })
    }
    
    const getDisplayFormatList = () => {

        collectionService.getDisplayFormats()
            .then((response) => {

                if (response?.hasError)
                    setState((prevState) => { return { ...prevState, columnSchemaError: response.errorMessage } });

                else if (response?.data) {
                    setState((prevState) => { return { ...prevState, displayFormatList: response?.data } });
                    resetErrorMessages();
                }
            })
    }

    // it gets the collection type list according to the sysConnectionTypeId
    const getCollectionTypeList = (sysConnectionTypeId) => {

        collectionService.getCollectionTypeList(sysConnectionTypeId)
            .then((response) => {
                if (response?.data)
                    setState((prevState) => { return { ...prevState, collectionTypeList: response.data, sysConnectionTypeId: sysConnectionTypeId } });
            })
    }

    // it fetches the column schema 
    const getColumnSchema = async (prevCollection,callback=null) => {
        setIsCloudLoading(true);
        resetErrorMessages();
        let dataCollectionModal;
        if (prevCollection) {
            dataCollectionModal = getDataCollectionModal(prevCollection);
        }
        else {
            dataCollectionModal = getDataCollectionModal(null);
        }

        if(collectionUId && state?.templateCollectionId){
            delete dataCollectionModal.collectionParameters;
        }
        else{
            delete dataCollectionModal.collectionColumns;
        }

        var response = await collectionService.getColumnSchema(dataCollectionModal);
        if(response){
            if (response?.hasError){
                setState((prevState) => { return { ...prevState, columnSchemaError: response?.errorMessage, columnSchemaList: [] } });
                setIsCloudLoading(false);

                setState((prevState) => { return { ...prevState, cloudCollectionError: response } });

                if (response?.errorMessage  == "Unauthorized") {
                    toast.error("Your connection has been expired. Please establish the connection again");
                }
                else {
                    toast.error(response?.errorMessage);
                }
            }
            else {
                setState((prevState) => { return { ...prevState, collectionColumnSchema: response?.data?.data } });
                //handleColumnSchema(response?.data?.data,prevCollection);
                if (!isTestedData && collectionUId && (!prevCollection?.templateCollectionId || !state?.templateCollectionId) && (!state?.collectionColumnSchema || state?.collectionColumnSchema.length < 0 )) {
                    prevCollection.collectionColumnSchema = response?.data?.data;
                    getTestedCollection(prevCollection);
                }
                if(callback != null){
                    callback(response?.data?.data);
                }
                setIsCloudLoading(false);
            }
        }
    }

    const handleToastClick = () => {  
        // window.location.href = '/connections';
        navigate('/connections');
      };

    const handleColumnSchema = (data, prevCollection) => {
        let columnSchemaList = data;

        _.forEach(columnSchemaList, function (column) {
            _.set(column, 'DisplayName', column?.ColumnName);
        })
        if (collectionUId) {
            columnSchemaList = mapPreviousSelectedColumns(prevCollection?.collectionColumns ? prevCollection?.collectionColumns : state.collectionColumns
                , data, columnSchemaList, true)
            setState((prevState) => { return { ...prevState, columnSchemaList: columnSchemaList, } });
        }
        if (!collectionUId) {
            // if column are already selected
            if (state.collectionColumns?.length > 0) {
                columnSchemaList = mapPreviousSelectedColumns(state.collectionColumns, data, columnSchemaList, false)
            }
            else {
                setState((prevState) => { return { ...prevState, collectionColumns: data, } });
                columnSchemaList = _.map(data, column => {
                    return { ...column, isSelected: true };
                })
            }
            setState((prevState) => { return { ...prevState, columnSchemaList: columnSchemaList, } });
        }
        if (state.columnSchemaError)
            resetErrorMessages();
    }

    // if any previous chosen column is available then it maps the data to the newly fetched column list
    const mapPreviousSelectedColumns = (collectionColumns, response, columnSchemaList, isOldCollection) => {

        _.forEach(collectionColumns, function (column) {
            let columnIndex = _.findIndex(response, function (col) {
                return col?.columnName?.toLowerCase().trim() ===
                    (isOldCollection ? column?.columnName : column?.ColumnName).toLowerCase().trim()
            });
            if (columnIndex !== -1) {
                if (isOldCollection)
                    columnSchemaList[columnIndex] = { ...column, ColumnDataType: columnSchemaList[columnIndex]?.ColumnDataType, isSelected: true };
                else
                    columnSchemaList[columnIndex] = { ...columnSchemaList[columnIndex], ...column, isSelected: true };
            }
        })
        return columnSchemaList;
    }

    // it fetches connection list
    const getConnectionList = () => {

        connectionService.getDataSources(true)
            .then((response) => {

                if (response?.data) {
                    setState((prevState) => { return { ...prevState, connectionList: response.data } });
                    resetErrorMessages();
                }
                else if (response?.hasError)
                    setState((prevState) => { return { ...prevState, basicInfoError: response.errorMessage } });
            })
    }

    // it fetches category list
    const getCategoryList = () => {

        collectionService.getCategoryList()
            .then((response) => {
                if (response?.data)
                    setState((prevState) => { return { ...prevState, categoryList: response.data } })
            })
    }

    // it contains all the basic info details to show when the first step is complete 
    const submittedBasicInfo = [
        !selectedConnection?.templateConnectionId ? null : {
            label: 'Data Connnection', value:
                _.filter(state?.connectionList, function (i) {
                    return i?.uId === state.connectionUId;
                })[0]?.name
        },
        { label: 'Data Collection Name', value: state.name },
        { label: 'Group Name', value: `${state.groupName} ${state.childGroupName ? ` > ${state.childGroupName}` : ''}` }
        // {
        //     label: 'Category', value:
        //         _.filter(state?.categoryList, function (i) {
        //             return i?.categoryUId === state.categoryUId;
        //         })[0]?.categoryName
        // }
    ]?.filter(item => item !== null);

    const selectedConnectionSubmited = [
        {
            label: 'Data Connnection', value:
                _.filter(state?.connectionList, function (i) {
                    return i?.uId === state.connectionUId;
                })[0]?.name
        }
    ]

    // it contains all the collection type and object details to show when the second step is complete 
    const submittedCollectionType = [
        selectedConnection?.templateConnectionId ? state?.collectionTypeList.length > 0
            ? {
                label: 'From', value:
                    _.filter(state?.collectionTypeList, function (i) {
                        return i?.id === state.sysCollectionTypeId;
                    })[0]?.typeDesc
            }
            : null : {
            label: 'Collection Type', value:
                _.filter(state?.collectionTypeList, function (i) {
                    return i?.id === state.sysCollectionTypeId;
                })[0]?.typeDesc
        },
        selectedConnection?.templateConnectionId ? null
            :
            { label: state.uiLabel, value: ((selectedConnection && (selectedConnection?.connectionTypeId == 2)) ? selectedConnection?.parameters?.filter(i => i.parameterName == RestServiceConstants.API_ENDPOINT)[0]?.parameterValue + state?.sourceName : state?.sourceName) },
    ]?.filter(item => item !== null);

    const submittedCloudObject = [
        { label: "", value: (state?.cloudCollectionObjects?.find(i => i.templateCollectionId == state?.templateCollectionId)?.name) },
    ];

    // it adds a new collection
    const addNewDataCollection = () => {
        resetErrorMessages();
        setIsSavingCollection(true);

        let dataCollectionModal = getDataCollectionModal();

        if (dataCollectionModal.isCollectionAsDataSource) {
            if(variableUId.length > 0){
                let updateUserModel = { ...UpdateUserModel };
                updateUserModel.uId = variableUId;
                updateUserModel.name = dataCollectionModal.name;
                updateUserModel.description = "";
                updateUserModel.sysDataTypeId = 8;
                updateUserModel.templateConnectionId = dataCollectionModal?.variable?.templateConnectionId;
                updateUserModel.complexTypeValue = JSON.stringify(isDynamicListValues);
                dataCollectionModal.variable = updateUserModel;
            }else{
                let userModel = { ...NewUserModel };
                userModel.name = dataCollectionModal.name;
                userModel.description = "";
                userModel.sysDataTypeId = 8;
                userModel.complexTypeValue = JSON.stringify(isDynamicListValues);
                userModel.templateConnectionId = isTemplateCompany ? _.get(_.find(state?.connectionList, { uId: state?.connectionUId }), 'id') : null;
                dataCollectionModal.variable = userModel;
            }
        }

        if(dataCollectionModal?.templateCollectionId){
            setIsCloudLoading(true);
            var allColumns = state?.collectionColumnSchema?.filter(i => i.isSelected);
            var columns = allColumns.map((i) => {
                var currentColl = dataCollectionModal.collectionColumns.filter(c => c.columnName == i.columnName);
                if(currentColl.length > 0){
                    const matchingFormat = findDisplayFormat(i.dataTypeName, state);
                    return {
                        collectionId: currentColl[0].collectionId,
                        columnName: currentColl[0].columnName,
                        dataTypeName: i.dataTypeName,
                        displayFormat: isNullOrEmpty(i.displayFormat) ? (matchingFormat?.displayFormat ?? AppEnum.DefaultDisplayFormat.General) : i.displayFormat,
                        dataType: matchingFormat?.sysDataTypeId ?? 6,
                        displayName: currentColl[0].displayName,
                        id: currentColl[0].id,
                        isDefault: currentColl[0].isDefault,
                        isSortable: currentColl[0].isSortable,
                        isFilterable: currentColl[0].isFilterable,
                        notQuerable: currentColl[0].notQuerable,
                        uId: currentColl[0].uId,
                        displayOrder: i.displayOrder ?? 0,
                    }
                }
                else{
                    const matchingFormat = findDisplayFormat(i.dataTypeName, state);
                    return { 
                        ...i, 
                        displayOrder: i.displayOrder ?? 0,
                        displayFormat: isNullOrEmpty(i.displayFormat) ? (matchingFormat?.displayFormat ?? AppEnum.DefaultDisplayFormat.General) : i.displayFormat,
                        dataType: matchingFormat?.sysDataTypeId ?? 6
                    };
                }
            })

            dataCollectionModal.collectionColumns = columns;
        }

        if(dataCollectionModal.collectionColumns.length > 0 && !dataCollectionModal?.templateCollectionId){
            dataCollectionModal["collectionColumns"] = updateDisplayOrder(dataCollectionModal?.collectionColumns, dataCollectionModal?.sysConnectionTypeId, "columns");
        }

        if(dataCollectionModal.collectionParameters.length > 0){
            dataCollectionModal["collectionParameters"] = updateDisplayOrder(dataCollectionModal?.collectionParameters, dataCollectionModal?.sysConnectionTypeId, "parameters");
        }

        if ((collectionUId && !isTemplateCollection) || (collectionUId && selectedConnection?.templateConnectionId)) {
            dataCollectionModal.uId = collectionUId;
            updateDataCollection(dataCollectionModal);
        }

        else {

            try {
                collectionService.saveDataCollection(dataCollectionModal)
                .then((response) => {
                    setIsSavingCollection(false);
                    setIsCloudLoading(false);
                    if (response?.hasError) {
                        setErrorMessage(response?.errorMessage);
                        toast.error(response?.errorMessage);
                    }
                    else {
                        resetErrorMessages();
                        navigate('/data-collections');
                        toast.success("Collection added successfully.");
                    }
                })
            } catch (error) {
                toast.error(error);
                setIsSavingCollection(false);
            }
        }
    }

    // updates the changes done in the collection details
    const updateDataCollection = (dataCollectionModal) => {

        if(isNullOrEmpty(dataCollectionModal.uId) && state?.templateConnectionId){
            dataCollectionModal.uId = collectionUId;
        }

        try {
            collectionService.updateDataCollection(dataCollectionModal)
            .then((response) => {
                setIsSavingCollection(false);
                setIsCloudLoading(false);
                if (response?.hasError) {
                    setErrorMessage(response?.errorMessage);
                    toast.error(response?.errorMessage);
                }
                else {
                    resetErrorMessages();
                    navigate('/data-collections');
                    toast.success("Collection updated successfully.");
                }

            })
        } catch (error) {
            setIsSavingCollection(false);
            toast.error(error);
        }
    }

    // it sets the error message state 
    const setErrorMessage = (response) => {
        setState((prevState) => { return { ...prevState, testCollectionError: response?.errorMessage } });
    }

    const windowRef = useRef();

    useEffect(() => {
        const interval = setInterval(() => {
            if (openConnectionPopup) {
                getToken(selectedConnection, stateConnectionParameter, windowRef, setState,
                    openConnectionPopup, setOpenConnectionPopup, setIsAuthorizeAgain, null);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [openConnectionPopup])

    const handleAuthorizeAgain = async() =>{
        authorizeAgain(setState, selectedConnection, windowRef, setOpenConnectionPopup, setStateConnectionParameter);
    }       

    // returns the final data collection modal
    const getDataCollectionModal = (prevCollection) => {
        let dataCollectionModal = new DataCollectionModal();
        let collection;
        if (prevCollection)
            collection = prevCollection;
        else
            collection = state;

        dataCollectionModal.connectionUId = collection.connectionUId;
        dataCollectionModal.groupName = collection.groupName;
        dataCollectionModal.childGroupName = collection.childGroupName;
        dataCollectionModal.name = collection.name;
        dataCollectionModal.sysCollectionTypeId = collection.sysCollectionTypeId;
        dataCollectionModal.sysConnectionTypeId = collection.sysConnectionTypeId;
        dataCollectionModal.sysCollectionSubTypeId = collection.sysCollectionSubTypeId;        
        dataCollectionModal.sourceName = collection.sourceName;
        dataCollectionModal.sourceNamePart2 = collection.sourceNamePart2;
        dataCollectionModal.sourceNamePart4 = collection.sourceNamePart4;
        dataCollectionModal.categoryUId = collection.categoryUId;
        dataCollectionModal.columnSchema = collection.columnSchema;
        dataCollectionModal.isCollectionAsDataSource = collection.isCollectionAsDataSource;
        dataCollectionModal.hasPrintableCollection = collection.hasPrintableCollection;
        dataCollectionModal.collectionParameters = collection.collectionParameters;
        dataCollectionModal.collectionFilterColumns = collection.collectionFilterColumns;
        dataCollectionModal.collectionColumns = collection.collectionColumns;
        dataCollectionModal.uId = collection.uId;
        dataCollectionModal.restRequestMethod = collection.restRequestMethod ? collection.restRequestMethod : state.restRequestMethod;
        dataCollectionModal.restBody = collection.restBody ? collection.restBody : state.restBody;
        dataCollectionModal.restSendAsCD = collection.restSendAsCD ? collection.restSendAsCD : state.restSendAsCD;
        dataCollectionModal.restDataTransformationScript = collection.restDataTransformationScript ? collection.restDataTransformationScript : state.restDataTransformationScript;
        dataCollectionModal.templateCollectionId = collection?.templateCollectionId;
        dataCollectionModal.sourceNamePart2 = collection.sourceNamePart2;
        dataCollectionModal.isFilterSupported = collection?.isFilterSupported;
        dataCollectionModal.isSortSupported = collection?.isSortSupported;
        dataCollectionModal.isPagingSupported = collection?.isPagingSupported;
        dataCollectionModal.areAllFieldsFilterable = collection?.areAllFieldsFilterable;
        dataCollectionModal.areAllFieldsSortable = collection?.areAllFieldsSortable;
        dataCollectionModal.columnApiEndpoint = collection?.columnApiEndpoint;
        dataCollectionModal.variableId = collection?.variableId;
        dataCollectionModal.variable = collection?.variable;
        dataCollectionModal.returnRawData = collection.returnRawData;

        return dataCollectionModal;
    }

    const testConnectionWhenAuthorizeAgain = (code) => {
        selectedConnection.parameters.filter((i) => i.parameterType == RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE)[0].parameterValue = code;
        var connection = {
            "name": selectedConnection.connectionName,
            "uId": selectedConnection.uId,
            "sysConnectionTypeId": selectedConnection.connectionTypeId,
            "isSaasAuthSource": false,
            "description": selectedConnection.description,
            "parameters": selectedConnection.parameters
        }
        connectionService.testDataSourceType(connection).then((res) => {
            if (res?.data?.data) {
                if (res?.data?.data?.error) {
                    setState((prevState) => {
                        return {
                            ...prevState, testCollectionError: res?.data?.data?.error,
                            testedDataList: [],
                            testedDataColumnKeys: [],
                            isTestingConnection: false
                        }
                    });
                }
                else {
                    connection.parameters.filter((i) => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN)[0].parameterValue = res?.data?.data?.access_token;
                    connectionService.updateDataSourceType(connection).then((updateRes) => {
                        setIsAuthorizeAgain(false);
                        setState((prevState) => {
                            return {
                                ...prevState, testCollectionError: "",
                                testedDataList: [],
                                testedDataColumnKeys: [],
                                isTestingConnection: false
                            }
                        });
                    }).catch((error) => {

                    });
                }
            }
        })
    }

    //updating collection active status
    const updateActiveStatus = (event) => {

        setActiveStatus(event.target.checked)
        setErrorMessage(null);

        const model = {
            uId: collectionUId,
            isActive: event.target.checked,
            fieldsToUpdate: ["IsActive"]
        };

        collectionService.updateDataCollection(model)
            .then((response) => {

                if (response?.hasError) {
                    setErrorMessage(response);
                    toast.error(response);
                }
                else {
                    resetErrorMessages();
                    toast.success("Collection updated successfully.");
                }
            })
    };

    // resets the error message states to null
    const resetErrorMessages = () => {
        setState((prevState) => {
            return {
                ...prevState, basicInfoError: null
                , columnSchemaError: null, testCollectionError: null, cloudCollectionError: null
            }
        })
    }

    const getCollectionObjectByType = () => {

        setErrorMessage(null);
        // setState((prevState) => { return { ...prevState, sourceNamePart2: "" } });
        setState((prevState) => { return { ...prevState, cloudCollectionObjects: [] } });

        let connectionTypeName =  selectedConnection?.connectionTypeName?.toLocaleLowerCase();
        collectionService.getCollectionObjectByType(connectionTypeName)
            .then((response) => {

                if (response?.data && response?.data.length > 0) {
                    setState((prevState) => { return { ...prevState, cloudCollectionObjects: response.data} });
                }
                else {
                    setState((prevState) => { return { ...prevState, cloudCollectionObjects: [] } });
                    setErrorMessage("No Data Present for this connection")
                }
            })
    }

    const getCurrentConnectionDetails = (connectionUId) => {
        connectionService.getDataSourceByUID(connectionUId).then((res) => {
            if (res?.data) {
                setSelectedConnection(res.data);
            }
        });
    }

    const getTestedCollection = (prevCollection) => {
        let collection = prevCollection ? prevCollection : state;

        let testDataCollectionModal = Object.assign({}, {
            connectionUId: collection.connectionUId,
            sysCollectionTypeId: collection.sysCollectionTypeId,
            sourceName: collection.sourceName,
            restRequestMethod: collection.restRequestMethod,
            restRequestIsAsync: true,
            restBody: collection.restBody,
            restSendAsCD: collection.restSendAsCD,
            collectionParameters: collection.collectionParameters,
            collectionColumns: collection.collectionColumnSchema,
            uId: collection.uId,
            returnFlatternData: collection.sysCollectionTypeId === 7 ? !(collection.returnRawData) : false,
            sourceNamePart4: collection.sourceNamePart4,
            restDataTransformationScript: collection?.restDataTransformationScript,
            returnRawData: collection.returnRawData
        });

        testData(testDataCollectionModal, null, null, null, null, null, null, null, setState, setIsAuthorizeAgain, setIsShowHeader);
    }

    // checks if all the basic info details are submitted
    let isBasicInfoSubmitted = (state.name && state.groupName && state.connectionUId) && (state.isCollectionAsDataSource ? (isDynamicListValues.DisplayColumn.trim().length > 0 && isDynamicListValues.ValueColumn.trim().length > 0 && isDynamicListValues.isDefaultValueCorrect) : true) && (typeof state.basicInfoError === "string" && state.name != initialCollectionName  ? (state.basicInfoError?.length == 0) : true);
    // checks if all the collection type details are submitted
    let isCollectionTypeSubmitted = (selectedConnection?.templateConnectionId) ? (state.sysCollectionTypeId) : true; // (state.sourceName && state.sysCollectionTypeId);
    // checks if all the collection type details are submitted
    let isParameterSubmitted = (state.collectionParameters?.length > 0);
    let isCollectionFilterColumnSubmitted = (state.collectionFilterColumns?.length > 0);
    // checks if any column and format is submitted
    let isColumnAndFormatSubmitted = (state.collectionColumns?.length > 0) && isManualColumnValid;
    let isSendBodyAs = true;
    let isDataFormationStep = true;
    let isDataAfterFormatingStep = (isShowHeader ? state.collectionColumnSchema?.length > 0 : state?.testedDataTransformedColumneKeys?.length > 0);
    let isConnectionSelected = state.connectionUId;
    let isObjectSelected = state.templateCollectionId;    

    

    const handleStepDataChange = (requireTestCollection = true) => {
        if(!isNullOrEmpty(collectionUId)){
            setIsStepDataChanged(true);
            if(requireTestCollection){
                setIsTestedAfterSaveAppears(false);
            }
            else{
                setIsTestedAfterSaveAppears(true);
            }
        }
    };

    const updateDisplayOrder = (collectionColumns, sysDataTypeID, field) => {
        let displayOrderValue = 0;
    
        return collectionColumns?.map((colDef) => {
            if (isTemplateCompany && field === "columns" && sysDataTypeID == AppEnum?.SysConnnectionTypeId?.REST_Service) {
                if (colDef?.isDefault) {
                    displayOrderValue++;
                    return { ...colDef, displayOrder: displayOrderValue };
                }
                return { ...colDef, displayOrder: 0 };
            } 
            else {
                displayOrderValue++;
                return { ...colDef, displayOrder: displayOrderValue };
            }
        });
    };

    const restSteps = [
        {
            label: CollectionStepLabelConstant.Select_Connection,
            description: (
                <SelectConnectionController
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    getCollectionTypeList={getCollectionTypeList}
                    setSelectedConnection={setSelectedConnection}
                    setParametersState={setParametersState}
                />
            ),
            isSelectConnection: true,
            optionalComponent: { labels: selectedConnectionSubmited, },
            isCollectionType: true,
            // isCollectionAsDataSource: state.isCollectionAsDataSource,
            disabled: isConnectionSelected && Object.keys(selectedConnection).length !== 0 ? false : true,
        },
        // {
        //     label: "Basic Information",
        //     description: (
        //         <BasicInformationController
        //             resetErrorMessages={resetErrorMessages}
        //             state={state}
        //             setState={setState}
        //             prevStepRef={prevStepRef}
        //             getCollectionTypeList={getCollectionTypeList}
        //             setSelectedConnection={setSelectedConnection}
        //             initialCollectionName={initialCollectionName}
        //         />
        //     ),
        //     isBasicInfo: true,
        //     optionalComponent: { labels: submittedBasicInfo, },
        //     isCollectionType: false,
        //     isCollectionAsDataSource: state.isCollectionAsDataSource,
        //     disabled: isBasicInfoSubmitted ? false : true,
        // },
        {
            label: CollectionStepLabelConstant.Collection_Type_And_Object,
            description: (
                <CollectionTypeAndObjectController
                    resetErrorMessages={resetErrorMessages}
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    selectedConnection={selectedConnection}
                    isRest={selectedConnection.connectionTypeId == 2 || selectedConnection?.baseConnectionTypeId == 2}
                    setIsChangedDataParams={setIsChangedDataParams}
                    isTestedData={isTestedData}
                    isTemplateCompany={isTemplateCompany}
                    setIsTestedData={setIsTestedData}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            optionalComponent: { labels: submittedCollectionType, },
            isCollectionType: (isCollectionTypeSubmitted && activeStep !== 1),
            disabled: isCollectionTypeSubmitted ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Send_Body_As,
            description: (
                <SendBodyAsController
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    isShowStep={state.restRequestMethod == "GET"}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isSendBodyAs: isSendBodyAs,
            optionalComponent: { collectionParameters: state.collectionParameters, },
            disabled: isSendBodyAs ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Parameters,
            description: (
                <CollectionParametersController
                    isTemplateCompany={isTemplateCompany}
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    setParametersState={setParametersState}
                    parametersState={parametersState}
                    dataCollectionModal={dataCollectionModal}
                    extractedParamters={extractedParamters}
                    setExtractedParamters={setExtractedParamters}
                    isTestedData={isTestedData}
                    setIsChangedDataParams={setIsChangedDataParams}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isParameterSubmitted: isParameterSubmitted,
            optionalComponent: { collectionParameters: state.collectionParameters, showSendAs : state.sysCollectionTypeId == 7},
            disabled: false,
        },
        {
            label: CollectionStepLabelConstant.Test_Data,
            description: (
                <TestDataController
                    state={state}
                    setState={setState}
                    getDataCollectionModal={getDataCollectionModal}
                    resetErrorMessages={resetErrorMessages}
                    handleAuthorizeAgain={handleAuthorizeAgain}
                    isAuthorizeAgain={isAuthorizeAgain}
                    setIsAuthorizeAgain={setIsAuthorizeAgain}
                    selectedConnection={selectedConnection}
                    getColumnSchema={getColumnSchema}
                    setIsTestedData={setIsTestedData}
                    isChangedDataParams={isChangedDataParams}
                    setIsChangedDataParams={setIsChangedDataParams}
                    setIsShowHeader={setIsShowHeader}
                    showSaveCollection={isStepDataChanged}
                    setIsTestedAfterSaveAppears={setIsTestedAfterSaveAppears}
                />
            ),
            isTestedData : isTestedData,
            optionalComponent: {testedDataList : state.testedDataList, testedDataColumnKeys : state.testedDataColumnKeys, returnRawData : state.returnRawData },
            disabled: isTestedData ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Data_Transformation_Script,
            description: (
                <DataTransformationScriptController
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isDataFormationStep: isDataFormationStep,
            optionalComponent: { restDataTransformationScript: state.restDataTransformationScript},
            disabled: isDataFormationStep ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Data_After_Transformation,
            description: (
                <DataAfterTransformationController
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isDataAfterFormatingStep: isDataAfterFormatingStep,
            optionalComponent: { testedDataTransformedList: state.testedDataTransformedList,  
                                testedDataTransformedColumneKeys: state.testedDataTransformedColumneKeys,
                                columnSchemaList: state.columnSchemaList},
            disabled: isDataAfterFormatingStep ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Select_Columns_And_Formatting,
            description: (
                <SelectColumnsController
                    state={state}
                    prevStepRef={prevStepRef}
                    setState={setState}
                    getColumnSchema={getColumnSchema}
                    resetErrorMessages={resetErrorMessages}
                    getDisplayFormatList={getDisplayFormatList}
                    collectionUId={collectionUId}
                    isRest={state?.sysCollectionTypeId === 7}
                    setIsManualColumnValid={setIsManualColumnValid}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isColumnAndFormatSubmitted: isColumnAndFormatSubmitted,
            optionalComponent: { collectionColumns: state.columnSchemaList, },
            disabled: isColumnAndFormatSubmitted ? false : true,
        },
        ...(isTemplateCompany ? [
            {
                label: CollectionStepLabelConstant.Collection_Column_Filters,
                description: (
                    <CollectionFilterColumnController
                        resetErrorMessages={resetErrorMessages}
                        state={state}
                        setState={setState}
                        prevStepRef={prevStepRef}
                        dataCollectionModal={dataCollectionModal}
                        setIsChangedDataParams={setIsChangedDataParams}
                        collectionFilterColumnState={collectionFilterColumnState}
                        setCollectionFilterColumnState={setCollectionFilterColumnState}
                    />
                ),
                isCollectionFilterColumnSubmitted: isCollectionFilterColumnSubmitted,
                optionalComponent: { collectionFilterColumns: state?.collectionFilterColumns, showSendAs : state.sysCollectionTypeId == 7},
                disabled: false,
            },
        ] : []),
        
        {
            label: CollectionStepLabelConstant.Basic_Information,
            description: (
                <BasicInformationController
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    getCollectionTypeList={getCollectionTypeList}
                    setSelectedConnection={setSelectedConnection}
                    initialCollectionName={initialCollectionName}
                    isDynamicListValues={isDynamicListValues}
                    setIsDynamicListValues={setIsDynamicListValues}
                    isVariableUsedInCollection={isVariableUsedInCollection}
                    isRest = {state?.sysCollectionTypeId === 7}
                    isTestedAfterSaveAppears={isTestedAfterSaveAppears}
                    setShowTestCollectionPopup={setShowTestCollectionPopup}
                    showSaveCollection={isStepDataChanged}
                    isTestedData={isTestedData}
                />
            ),
            isBasicInfo: true,
            optionalComponent: { labels: state?.name ? submittedBasicInfo : null, },
            isCollectionType: state?.name ? false : true,
            isCollectionAsDataSource: state.isCollectionAsDataSource,
            disabled: isBasicInfoSubmitted ? false : true,
        },
        
    ];

    if(isShowHeader){
        restSteps.splice(7,1);
    }

    const steps = [
        {
            label: CollectionStepLabelConstant.Select_Connection,
            description: (
                <SelectConnectionController
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    getCollectionTypeList={getCollectionTypeList}
                    setSelectedConnection={setSelectedConnection}
                    setParametersState={setParametersState}
                />
            ),
            isSelectConnection: true,
            optionalComponent: { labels: selectedConnectionSubmited, },
            isCollectionType: true,
            // isCollectionAsDataSource: state.isCollectionAsDataSource,
            disabled: isConnectionSelected && Object.keys(selectedConnection).length !== 0 ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Collection_Type_And_Object,
            description: (
                <CollectionTypeAndObjectController
                    resetErrorMessages={resetErrorMessages}
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    selectedConnection={selectedConnection}
                    isRest={selectedConnection.connectionTypeId == 2 || selectedConnection?.baseConnectionTypeId == 2}
					setIsChangedDataParams={setIsChangedDataParams}
                    isTestedData={isTestedData}
                    isTemplateCompany={isTemplateCompany}
					activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    setIsTestedData={setIsTestedData}  
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            optionalComponent: { labels: submittedCollectionType, },
            isCollectionType: (isCollectionTypeSubmitted && activeStep !== 1),
            disabled: isCollectionTypeSubmitted ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Parameters,
            description: (
                <CollectionParametersController
                    isTemplateCompany={isTemplateCompany}
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    setParametersState={setParametersState}
                    parametersState={parametersState}
                    dataCollectionModal={dataCollectionModal}
                    extractedParamters={extractedParamters}
                    setExtractedParamters={setExtractedParamters}
                    isTestedData={isTestedData}
                    setIsChangedDataParams={setIsChangedDataParams}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isParameterSubmitted: isParameterSubmitted,
            optionalComponent: { collectionParameters: state.collectionParameters, },
            disabled: false,
        },
        {
            label: CollectionStepLabelConstant.Test_Data,
            description: (
                <TestDataController
                    state={state}
                    setState={setState}
                    getDataCollectionModal={getDataCollectionModal}
                    resetErrorMessages={resetErrorMessages}
                    handleAuthorizeAgain={handleAuthorizeAgain}
                    isAuthorizeAgain={isAuthorizeAgain}
                    setIsAuthorizeAgain={setIsAuthorizeAgain}
                    selectedConnection={selectedConnection}
                    getColumnSchema={getColumnSchema}
                    setIsTestedData={setIsTestedData}
                    isChangedDataParams={isChangedDataParams}
                    setIsChangedDataParams={setIsChangedDataParams}
                    isShowHeader={isShowHeader}
                    isTestedData={isTestedData}
                    setIsShowHeader={setIsShowHeader}
                    showSaveCollection={isStepDataChanged}
                    setIsTestedAfterSaveAppears={setIsTestedAfterSaveAppears}
                />
            ),
            isTestedData: isTestedData,
            optionalComponent: { testedDataList: state.testedDataList, testedDataColumnKeys: state.testedDataColumnKeys },
            disabled: isTestedData ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Data_Transformation_Script,
            description: (
                <DataTransformationScriptController
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isDataFormationStep: isDataFormationStep,
            optionalComponent: { restDataTransformationScript: state.restDataTransformationScript },
            disabled: isDataFormationStep ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Data_After_Transformation,
            description: (
                <DataAfterTransformationController
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    isShowHeader={isShowHeader}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isDataAfterFormatingStep: isDataAfterFormatingStep,
            optionalComponent: { testedDataTransformedList: state.testedDataTransformedList,  
                                testedDataTransformedColumneKeys: state.testedDataTransformedColumneKeys,
                                columnSchemaList: state.columnSchemaList,collectionColumnSchema: state.collectionColumnSchema},
            disabled: isDataAfterFormatingStep ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Select_Columns_And_Formatting,
            description: (
                <SelectColumnsController
                    state={state}
                    prevStepRef={prevStepRef}
                    setState={setState}
                    getColumnSchema={getColumnSchema}
                    resetErrorMessages={resetErrorMessages}
                    getDisplayFormatList={getDisplayFormatList}
                    collectionUId={collectionUId}
                    isRest={state?.sysCollectionTypeId === 7}
                    setIsManualColumnValid={setIsManualColumnValid}
                    showSaveChangesButton={handleStepDataChange}
                />
            ),
            isColumnAndFormatSubmitted: isColumnAndFormatSubmitted,
            optionalComponent: { collectionColumns: state.columnSchemaList, },
            disabled: isColumnAndFormatSubmitted ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Basic_Information,
            description: (
                <BasicInformationController
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    getCollectionTypeList={getCollectionTypeList}
                    setSelectedConnection={setSelectedConnection}
                    initialCollectionName={initialCollectionName}
                    isDynamicListValues={isDynamicListValues}
                    setIsDynamicListValues={setIsDynamicListValues}
                    isVariableUsedInCollection={isVariableUsedInCollection}
                    isRest = {state?.sysCollectionTypeId === 7}
                    isTestedAfterSaveAppears={isTestedAfterSaveAppears}
                    setShowTestCollectionPopup={setShowTestCollectionPopup}
                    showSaveCollection={isStepDataChanged}
                    isTestedData={isTestedData}
                />
            ),
            isBasicInfo: true,
            optionalComponent: { labels: state?.name ? submittedBasicInfo : null, },
            isCollectionType: state?.name ? false : true,
            isCollectionAsDataSource: state.isCollectionAsDataSource,
            disabled: isBasicInfoSubmitted ? false : true,
        }
    ];

    const cloudConnectionSteps = [

        state?.uId ? (
            {
                label: CollectionStepLabelConstant.Basic_Information,
                description: (
                    <BasicInformationController
                        resetErrorMessages={resetErrorMessages}
                        state={state}
                        setState={setState}
                        prevStepRef={prevStepRef}
                        getCollectionTypeList={getCollectionTypeList}
                        setSelectedConnection={setSelectedConnection}
                        initialCollectionName={initialCollectionName}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        isCloudStep={true}
                        selectedConnection={selectedConnection}
                        isTestedAfterSaveAppears={isTestedAfterSaveAppears}
                        setShowTestCollectionPopup={setShowTestCollectionPopup}
                        showSaveCollection={isStepDataChanged}
                    />
                ),
                isBasicInfo: true,
                optionalComponent: { labels: state?.name ? submittedBasicInfo : null, },
                isCollectionType: state?.name ? false : true,
                isCollectionAsDataSource: state.isCollectionAsDataSource,
                disabled: isBasicInfoSubmitted ? false : true,
            }
        ) :
            ({
                label: CollectionStepLabelConstant.Select_Connection,
                description: (
                    <SelectConnectionController
                        resetErrorMessages={resetErrorMessages}
                        state={state}
                        setState={setState}
                        prevStepRef={prevStepRef}
                        getCollectionTypeList={getCollectionTypeList}
                        setSelectedConnection={setSelectedConnection}
                        setParametersState={setParametersState}
                    />
                ),
                isSelectConnection: true,
                optionalComponent: { labels: selectedConnectionSubmited, },
                isCollectionType: true,
                // isCollectionAsDataSource: state.isCollectionAsDataSource,
                disabled: isConnectionSelected && Object.keys(selectedConnection).length !== 0 ? false : true,
            }),
        {
            label:CollectionStepLabelConstant.Collection_Type_And_Object,
            description: (
                <CollectionTypeAndObjectController
                    resetErrorMessages={resetErrorMessages}
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    selectedConnection={selectedConnection}
                    isRest={false}
                    isTemplateCompany={isTemplateCompany}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    setIsTestedData={setIsTestedData}
                />
            ),
            optionalComponent: { labels: submittedCollectionType },
            isCollectionType: (isCollectionTypeSubmitted && activeStep !== 1),
            disabled: isCollectionTypeSubmitted ? false : true,
        },
        {
            label: CollectionStepLabelConstant.Select_An_Object,
            description: (
                <SelectObjectController
                    resetErrorMessages={resetErrorMessages}
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    selectedConnection={selectedConnection}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    getCollectionObjectByType={getCollectionObjectByType}
                />
            ),
            optionalComponent: { labels: submittedCloudObject, },
            isCollectionType: true,
            disabled: isObjectSelected ? false: true,
        },
        {
            label: CollectionStepLabelConstant.Data_View_Columns,
            description: (
                <ColumnSelectionController
                    maunalColumns={maunalColumns}
                    columnOverriden={columnOverriden}
                    setColumnOverriden={setColumnOverriden}
                    getColumnSchema={getColumnSchema}
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    setSelectedConnection={setSelectedConnection}
                    selectedConnection={selectedConnection}
                    saveCollection={addNewDataCollection}
                    isBasicInfoSubmitted={isBasicInfoSubmitted}
                    setActiveStep={setActiveStep}
                    activeStep={activeStep}
                    isUpdatingCollection={isNullOrEmpty(collectionUId) ? false : true}
                    isLoading={isCloudLoading}
                    setIsLoading={setIsCloudLoading}
                    getCollectionObjectByType={getCollectionObjectByType}
                    prevCloudFilterationData={prevCloudFilterationData}
                    isCloudFilterationData={isCloudFilterationData}
                    setIsCloudFilterationData={setIsCloudFilterationData}
                    handleAuthorizeAgain={handleAuthorizeAgain}
                    isAuthorizeAgain={isAuthorizeAgain}
                    setSearchOptionsParameters={setSearchOptionsParameters}
                    isSearchOptionsAvailable={isSearchOptionsAvailable}
                    searchOptionsParameters={searchOptionsParameters}
                    setIsSearchOptionsAvailable={setIsSearchOptionsAvailable}
                />
                // <></>
            )
        }
    ];


    // it scrolls to the previous step
    const scrollToPreviousStep = () => {
        setTimeout(() => {
            // prevStepRef?.current?.scrollIntoView({ behavior: 'smooth', block: "nearest" });
        }, 100)
    }

    const handleSaveChangesWithPopupedButton = () => {

        const testCollectionStep = (state?.sysCollectionTypeId === 7) ? 4 : 3
        if(activeStep < testCollectionStep){
            setShowTestCollectionPopup(true);
        }
        else{
            if(activeStep >= testCollectionStep){
                if(!isTestedAfterSaveAppears){
                    setShowTestCollectionPopup(true);
                    return;
                }
            }
            if(showTestCollectionPopup){
                setShowTestCollectionPopup(false);
            }
            addNewDataCollection();
        }
    }

    return (
        <>
            <ASStepper
                sysCollectionTypeId={state?.sysCollectionTypeId}
                backTwoStep={(selectedConnection?.templateConnectionId) ?
                    ((selectedConnection?.templateConnectionId && (state.collectionTypeList.length <= 0 || state.collectionTypeList.length == 1))) :
                    (state.restRequestMethod == "GET" && activeStep == 3 && state?.sysCollectionTypeId === 7)}
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                scrollToPreviousStep={scrollToPreviousStep}
                steps={(selectedConnection?.length == 0 && collectionUId) ? [] : (selectedConnection?.templateConnectionId) ? cloudConnectionSteps :
                    (state?.sysCollectionTypeId === 7) ? restSteps : steps}
                uId={isTemplateCollection ? null : collectionUId}
                isTestingConnection={state.isTestingConnection}
                save={addNewDataCollection}
                setErrorMessage={resetErrorMessages}
                activeStatus={activeStatus}
                updateActiveStatus={updateActiveStatus}
                isTemplateCollection={isTemplateCollection}
                isSavingCollection={isSavingCollection}
                stepperViewFromConnection={stepperViewFromConnection}
                variableList={state.variableList}
                isShowHeader={isShowHeader}
                isCloudSteps={selectedConnection?.templateConnectionId}
                collectionUId={collectionUId}
                isCollectionSteps={true}
                showSaveCollection={isStepDataChanged}
                handleSaveChangesWithPopupedButton={handleSaveChangesWithPopupedButton}
                isTestedAfterSaveAppears={isTestedAfterSaveAppears}
                setShowTestCollectionPopup={setShowTestCollectionPopup}
                isRestSteps={(state?.sysCollectionTypeId === 7) ? true : false}
                isTestedData={isTestedData}
                />

            <MuiDialog
             isShowPopUp={showTestCollectionPopup}
             primaryButtonAction={() => {
                    setActiveStep((state?.sysCollectionTypeId === 7) ? 4 : 3)
                    setShowTestCollectionPopup(false)
                }}
             primaryButtonTitle="Go to Test Collection"
             titleDescription="Please test your collection first."
            />
        </>
    );
}
