import { useMemo } from "react";
import _ from "lodash"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { toast } from "react-toastify";

export const CollectionFilter = (props) => {
    const { parameter, index, collectionUId, filterConfig,openDataVisualizer } = props;

    function isValidJsonFunc(jsonString) {
        try {
            JSON.parse(jsonString);
            return true; // JSON is valid
        } catch (e) {
            toast.error("Invalid Parameter");
            return false; // JSON is invalid
        }
    }

    const filterGroups = useMemo(() => {
        const isValidJson = isValidJsonFunc(parameter?.defaultValue)
        if (isValidJson)
            return JSON.parse(parameter?.defaultValue)
        else return [];
    }, [parameter?.defaultValue])

 


    const isSort = useMemo(() => parameter?.parameterName === "Sort" ? true : false, [parameter?.parameterName])

    const isFilter = parameter?.parameterName === "Filter" ? true : false;

    const getFilterType = (typeParam, valueParam) => {
        const filterArray = filterConfig?.filterTypeArray

        for (const filter of filterArray) {
            if (filter.type.includes(typeParam)) {

                for (const val of filter.value) {
                    if (val.value === valueParam) {
                        return ` ${val.name}`;
                    }
                }
            }
        }

    }




    return (
        <div key={index} className="mt-5">
            <div className="filter-header">
                <div className="parameter-heading " >

                    <ArrowForwardIosIcon />{isSort ? "Sort by" : isFilter ? "Filter" : "Limit to"}
                </div>

                <button onClick={openDataVisualizer}>edit</button>
            </div>
            {
                isSort ?
                    <div className="filter-box">
                        {filterGroups?.map((item, index) => {
                            return (
                                <div className="filter-value">
                                    {item.displayName}
                                    <span className="bold"> {item.value}</span>
                                </div>
                            )
                        })}
                    </div>
                    :

                    isFilter ?
                        <div className="filter-box">
                            {(filterGroups?.filter(group => group?.filters?.length > 0))?.map((group, groupIndex) => (
                                <div key={groupIndex}>

                                    {group?.filters?.map((item, conditionIndex) => {
                                        console.log(item)
                                        return (
                                            <div className="filter-value">
                                                <div className="" key={conditionIndex}>
                                                    {item?.displayName} {getFilterType(item?.dataTypeName, item.condition)}
                                                    <span className="bold"> {item?.dateLabel ? item?.dateLabel : item?.valueLabel ? item?.valueLabel : item?.value}</span>
                                                    <span className="italic">{group?.filters.length !== conditionIndex + 1 ? ` ${item.operator}` : ""}</span>

                                                </div>

                                            </div>

                                        )
                                    })}
                                    <div className="italic" style={{ marginLeft: "15px" }}>{(filterGroups?.filter(group => group?.filters?.length > 0)).length !== groupIndex + 1 && group.operator}</div>
                                </div>

                            ))
                            }
                        </div>
                        :
                        <div className="filter-box">
                            <div className="filter-value">
                                {filterGroups?.limit} rows
                            </div>
                        </div>
            }
        </div>
    )
}