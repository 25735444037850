import { ASDataCollections } from "../../../shared/menu-icons/as-data-collections";
import BoltIcon from '@mui/icons-material/Bolt';
import { ParameterTypeValue } from "../../../constants/app-enum";
import './plugin-parameters.scss';
import SheetsOverlay from "../SheetsOverlay/sheets-overlay";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SearchParameterController } from "../../../controllers/asheetPlugin/search-parameter/search-parameters";
import { getClientDateTime, truncateString } from "../../../shared/code-challanges/code-challanges";
import { CollectionFilter } from "../search-parameters/FilterParameters.js";
import { ASButton } from "../../../shared/as-button/as-button";
import { useMemo } from "react";
import moment from "moment";

const PluginParameter = (props) => {

    const { handleBack, selectedCollection,
        state, isLinked, isOpen, activeSheetName, setState, activeSheet, handleRunOrRefresh, filterConfig, openDataVisualizer } = props;


    const connectionName = useMemo(() => {
        return truncateString(selectedCollection?.connection?.name, 11)
    }, [selectedCollection?.connection?.name])


    const selectedConnectionName = useMemo(() => {
        return truncateString(selectedCollection?.name, 11)
    }, [selectedCollection?.name])

    const groupName = useMemo(() => {
        return truncateString(selectedCollection?.groupName, 13)
    }, [selectedCollection?.groupName])

    const selectedCollectionName = useMemo(() => {
        return truncateString(selectedCollection?.name, 25)
    }, [selectedCollection?.name])

    const formattedTime = useMemo(() => {
        const lastRenderTime = activeSheet.current?.lastRenderSuccessfulDateTime;
        if (!lastRenderTime) return moment().format('h:mm A');

        const localTime = getClientDateTime(lastRenderTime)

        const momentTime = moment(localTime);
        return momentTime.isSame(moment(), 'day')
            ? momentTime.format('h:mm A')
            : momentTime.format('MMM D, h:mm A');
    }, [activeSheet.current]);

    return (
        <div className="pluginContainer">
            <div className="header-flex">
                <div className="flex">
                    <div>
                        <img src={`/assets/back-button.svg`} alt="Back" style={{ marginLeft: "5px", marginTop: "5px", cursor: "pointer" }}
                            onClick={handleBack} />
                    </div>

                    <div className="source">
                        <img
                            src={`../../../assets/${selectedCollection?.connection?.connectionImageIcon}`}
                            alt="Xero"
                        />{' '}
                        <div className="parameter-heading">
                            {connectionName}
                            <ArrowForwardIosIcon />
                            {selectedConnectionName}
                        </div>

                    </div>
                </div>

                <div className="add-icon-btn">+</div>
            </div>

            <div className="parameter-container">
                <div>
                    <div className="flex mt-5">
                        <ASDataCollections className="group-image" flag={false} />
                        Group
                    </div>
                    <div className="ml-10 parameter-heading">

                        {groupName}
                        <ArrowForwardIosIcon />
                        {selectedCollectionName}
                    </div>
                </div>

                <div className="collection-params-container">

                    <div style={{ marginInline: '5px' }}>


                        {state?.searchParameters?.map((parameter, index) => {
                           
                            return (
                                <div>
                                    {index === 0 &&
                                        <div className="parameter-heading mt-5 ">
                                            <ArrowForwardIosIcon /> Search Parameters
                                        </div>
                                    }
                                    <SearchParameterController
                                        parameter={parameter}
                                        index={state?.collectionParameters.findIndex(item => item.id === parameter.id)}
                                        state={state}
                                        setState={setState} />
                                </div >
                            )
                        })}

                        {state?.filterSortParameters?.map((parameter, index) => {
                            return (
                                <div>

                                    <CollectionFilter
                                        parameter={parameter}
                                        index={index}
                                        collectionUId={selectedCollection?.uId}
                                        sheetName={activeSheet?.sheetName}
                                        filterConfig={filterConfig}
                                        openDataVisualizer={openDataVisualizer}
                                    />
                                </div >
                            )
                        })}

                        {
                            !state?.filterSortParameters?.length && state?.templateCollectionId &&
                            <div className="filter-header mt-5">
                                <div className="parameter-heading " >

                                    <ArrowForwardIosIcon />Select Filter/Sort
                                </div>

                                <button onClick={openDataVisualizer}>edit</button>
                            </div>

                        }


                    </div >
                </div >
                {
                    activeSheetName
                        ? (
                            <div className="details-header">
                                <div className="parameter-heading">
                                    <ArrowForwardIosIcon />{"Detail"}
                                </div>
                                <div className="details-box">
                                    <div className="details-value">
                                        Last run at {formattedTime} Successfully
                                    </div>
                                    <div className="details-value">
                                        Linked to {activeSheetName}
                                    </div>
                                </div>
                            </div>
                        )
                        : null
                }

            </div>


            <div className="run-btn">
                <ASButton
                    primary={true}
                    variant="contained"
                    sx={{ width: "100%" }}
                    onClick={() => handleRunOrRefresh(state)}
                >
                    <BoltIcon sx={{ color: "yellow" }} /> {isLinked ? "REFRESH" : "RUN"}
                </ASButton>
            </div>
            {
                isOpen &&
                <SheetsOverlay
                    connectionIcon={`${selectedCollection?.connection?.connectionImageIcon}`}
                    sheetsIcon={`/assets/google-sheets-icon.png`}
                    property={selectedCollection?.name}
                />
            }
        </div >
    )
}

export default PluginParameter