import React from 'react'
import "./CheckoutView.scss";
import UpdateCheckoutView from '../../common/calculateCheckout/UpdateCheckoutView';

function UpdateCheckoutPrice({ planList, onHandlePlanList, featureData, developerPrice, userList, hoursList, user,
    setUser, hour, setHour, totalPrice, isDeveloperSetUp, setDeveloperSetUp, pFlag, onBuyPlan, buttonDisabled }) {
    return (
        <div className='update-checkout-div'>
            <UpdateCheckoutView
                planList={planList} onHandlePlanList={onHandlePlanList}
                featureData={featureData} developerPrice={developerPrice}
                userList={userList}
                hoursList={hoursList}
                user={user}
                setUser={setUser}
                hour={hour}
                setHour={setHour}
                totalPrice={totalPrice}
                isDeveloperSetUp={isDeveloperSetUp}
                setDeveloperSetUp={setDeveloperSetUp}
                onBuyPlan={onBuyPlan}
                buttonDisabled={buttonDisabled}
            />
        </div>
    )
}

export default UpdateCheckoutPrice