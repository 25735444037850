import React, { useEffect } from "react";
import UsersListController from "./controllers/users-list/users-list-controller";
import UserPermissionController from "./controllers/user-permission/user-permission-controller";
import DataCollectionsController from "./controllers/data-collections/data-collections-controller";
import AddNewUserController from "./controllers/users-list/add-new-user-controller";
import { AddNewConnection } from "./controllers/connections/add-new-connection/add-new-connection";
import { ConnectionsList } from "./controllers/connections/connections-list/connections-list";
import { Connections } from "./controllers/connections/connections";
import { Layout } from "./controllers/layout/layout";
// import { LoginSignUp } from "./components/login-signup/login-signup";
import LoginController from "./controllers/login/login";
import SignupController from "./controllers/signup/signup";

import GroupsController from './controllers/group-subgroups/groups-controller';

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AddNewDataCollection } from "./controllers/data-collections/add-new-data-collection/add-new-data-collection";
import { DataCollectionsOutlet } from "./controllers/data-collections/data-collections-outlet";
import UsersPermissionForCollectionController from "./controllers/data-collections/users-permission-for-collection/users-permission-for-collection-controller";
import { getToken } from "./shared/local-storage-handler/local-storage-handler";
import ChangePassword from "./controllers/header-username-menu/changePassword/ChangePassword";
import AcceptInvitation from "./controllers/connections/acceptInvitation/accept-Invitation";
import VariablesPopup from "./controllers/connections/variables/variables-Popup";
import Variables from "./controllers/connections/variables/variables-controller";
import BeginOAuth from "./components/auth-callback/begin-oauth";
import AuthCallback from "./components/auth-callback/auth-callback";
import ForgetPasswordController from "./controllers/forget-password/forget-password";

import Checkout from "./controllers/checkout-process/checkout/Checkout";
import OrderCompleteController from "./controllers/order-complete/order-complete";

import useAppTitle from "./components/app-title/app-title";
import { Settings } from "./components/settings/settings";
import PageNotFoundController from "./controllers/page-not-found/page-not-found-controller";

import { AddNewConnectionExcel } from "./controllers/connections/add-new-connection-excel/add-new-connecton-excel";
import LayoutExcel from "./controllers/layout-excel/layout-excel";
import DataVisualizerController from "./controllers/data-collections/DataVisualizer/data-visualizer-controller";

// import PluginParameter from "./components/asheetPlugin/plugin-parameters/plugin-parameters";
import PluginController from "./controllers/asheetPlugin/plugin/pluginController";
import AsheetPlugInHeader from "./controllers/asheetPlugin/asheetLayout/asheet-plugin-header";
// import StartingPageContoller from "./controllers/asheetPlugin/starting-page/starting-page";
import AddConnectionContoller from "./controllers/asheetPlugin/add-connections/add-connection";
import OpenDirect from "./controllers/open-direct/open-direct";
import PluginParameterController from "./controllers/asheetPlugin/plugin-parameter/plugin-parameter";
import ExternalSignIn from "./controllers/external-signin/external-signin";
import { AppEnum } from "./constants/app-enum";
import jwtDecode from "jwt-decode";
import UnauthorizedResourceController from "./controllers/unauthorized-resource/unauthorized-resource-controller";

export const ProtectedRoute = ({ children }) => {
  if (!getToken()) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export const RoleBasedAuthorization = ({ children }) => {
  let userRole = "";

  const location = useLocation();

  const aSTenantId = sessionStorage.getItem('ASTenantId') || localStorage.getItem('ASTenantId');
        // aSTenantId = localStorage.getItem('ASTenantId');
  const decodedToken = jwtDecode(getToken());

  const tenantList = JSON.parse(decodedToken?.tenants);

  if (aSTenantId){
      const selectedTenant = tenantList?.find(item => item.ASTenantId === aSTenantId);
            if (selectedTenant) {
                userRole = selectedTenant.UserRoleId;
            }
  }

  const routes = AppEnum.Routes;

  const currentRoute = routes.find(r => location.pathname.includes(r.path));

  if(currentRoute?.userRoleIds.includes(userRole)){
    return children;
  }

  return <Navigate to="/unauthorized-resource" replace state={{ from: location.pathname }}/>;
}



const AppRouting = () => {

  const location = useLocation();
  const [getTitle] = useAppTitle();

  useEffect(() => {
    getTitle() //calling hook to dynamically change the app title
  }, [location.pathname]);

  let routes = (
    <Routes>
      <Route path="/connections/add-connection-excel"
        element={<ProtectedRoute>
          <LayoutExcel />
          <AddNewConnectionExcel />
        </ProtectedRoute>}>
      </Route>

      <Route path="/plugin" element={
        <ProtectedRoute>
          <AsheetPlugInHeader />
        </ProtectedRoute>
      }>
        <Route path="/plugin" element={<PluginController />} />
        <Route path="/plugin/add-connection" element={<AddConnectionContoller />} />
        <Route path="/plugin/data-collections/:collectionUId" element={<PluginParameterController />} />
        {/* <Route path="/plugin" element={<StartingPageContoller />} /> */}
        {/* <Route path="/plugin/add-connection" element={<AddConnectionContoller />} /> */}
      </Route>

      {/* <Route path="/connections/add-connection-excel/" element={<AddNewConnectionExcel />} /> */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >

        <Route path="/connections" element={<Connections />}>
          <Route path="/connections" element={<ConnectionsList />} />

          <Route
            path="/connections/add-connection"
            element={<RoleBasedAuthorization>
                      <AddNewConnection />
                    </RoleBasedAuthorization>}
          />
          <Route
            path="/connections/edit-connection/:datasourceId"
            element={<RoleBasedAuthorization> 
                        <AddNewConnection />
                      </RoleBasedAuthorization>}
          />


        </Route>


        <Route path="/groups" element={<GroupsController />} />


        <Route
          path="/book-an-expert"
          element={<RoleBasedAuthorization>
                    <Checkout pFlag={"BUY"} /> 
                  </RoleBasedAuthorization> }
      />

      <Route
        path="/update-checkout"
        element={<RoleBasedAuthorization> 
                    <Checkout pFlag={"update"} />
                  </RoleBasedAuthorization> }
      />

        <Route path="/variables" element={<RoleBasedAuthorization> 
                                            <Variables />
                                          </RoleBasedAuthorization>} 
        />
        <Route
          path="/variables/add-new-variables-popup"
          element={<VariablesPopup />}
        />
        <Route
          path="/variables/update-variable/:variableUId"
          element={<VariablesPopup />}
        />
        <Route path="/settings" element={<Settings />} />
        <Route path="/changepassword" element={<ChangePassword />} />


        <Route path="/users" element={<UsersListController />} />

        <Route
          path="/users/user-permission"
          element={
            <RoleBasedAuthorization> 
              <UserPermissionController />
              </RoleBasedAuthorization>
            }
        />
        <Route path="/users/add-new-user" element={<AddNewUserController />} />

        <Route path="/data-collections" element={<DataCollectionsOutlet />}>
          <Route
            path="/data-collections"
            element={<DataCollectionsController />}
          />
          <Route
            path="/data-collections/add-new-data-collection"
            element={<RoleBasedAuthorization> 
                      <AddNewDataCollection />
                    </RoleBasedAuthorization>}
          />
          <Route
            path="/data-collections/update-collection/:collectionUId"
            element={<RoleBasedAuthorization>
                      <AddNewDataCollection />
                    </RoleBasedAuthorization>}
          />

          <Route
            path="/data-collections/user-permissions/:collectionUId"
            element={<RoleBasedAuthorization>                  
              <UsersPermissionForCollectionController />
            </RoleBasedAuthorization>}
          />

        </Route>



        <Route path="/" element={<Navigate replace to="/connections" />} />
      </Route>

      <Route path="/login" element={<LoginController pFlag={"login"} />} />
      <Route path="/signup" element={<SignupController pFlag={"signup"} />} />

      <Route path="/begin-oauth" element={<BeginOAuth />} />
      <Route path="/auth-callback" element={<AuthCallback />} />

      {/* it is used for checkout process */}
      <Route path="/checkout" element={<Checkout />} />

      <Route path="/order-complete" element={<OrderCompleteController />} />

      <Route path="/forgotpassword" element={<ForgetPasswordController pFlag={"forgotpassword"} />} />
      <Route path="/resetpassword" element={<ForgetPasswordController pFlag={"resetpassword"} />} />

      <Route path="/acceptinvitation" element={<AcceptInvitation />} />
      <Route path="/page-not-found" element={<PageNotFoundController />} />  
      <Route path="/collection/data-visualizer" element={<DataVisualizerController/>} />
      <Route path="/open-direct" element={<OpenDirect />} /> 
      <Route path="/plugin" element={<PluginController />} />
      <Route path="/plugin/data-collections/:collectionUId" element={<PluginParameterController />} />
      {/* <Route path="/plugin/data-collections/:collectionUId" element={<PluginParameter />} /> */}
      <Route path="/login-jwt-auth" element={<ExternalSignIn />} />
      <Route path="*" element={<Navigate replace to="/page-not-found" />} />
      <Route path="/unauthorized-resource" element={<UnauthorizedResourceController />} />
    </Routes>
  );

  return <React.Fragment>{routes}</React.Fragment>;
};

export default AppRouting;
