import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import "./asheet-plugin-header.scss";
import { ASheetCompleteLogoSvg } from "../../../constants/common-svgs";
import { HiMenuAlt1 } from "react-icons/hi";
import { PageContent } from "../../../controllers/layout/page-content/page-content";
import { useASContext } from "../../../controllers/context-api/as-context";
import { onLogoutHandler } from "../../../controllers/header-username-menu/username-menu-container";
import { useNavigate } from "react-router-dom";
import { getToken, removeItem, setToken } from "../../../shared/local-storage-handler/local-storage-handler";
import jwt from 'jwt-decode'
import { useEffect, useState } from "react";
import TenantsMenu from "../../tenants-menu/tenants-menu";
import { setSessionStorageItem } from "../../../shared/session-storage-handler/session-storage-handler";
import { getSubscription } from "../../../services/checkout-service";

const AsheetPluginHeader = (props) => {

    const { handleBackClick, isBackIconVisible, selectedConnection } = props;
    const [tenants, setTenants] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [tenantAnchorEl, setTenantAnchorEl] = useState(null);
    const asContext = useASContext();
    const navigate = useNavigate();

    // to be removed later
    const onLogoutUser = () => {
        let menu = { link: "/login" };
        onLogoutHandler(menu, false, asContext, navigate);
    }

    // it opens the tenants menu on mouse hover
    const openTenantMenu = (event) => {

        setIsMenuOpen(true);
        setTenantAnchorEl(event.currentTarget);
    }

    // it closes the tenants menu on mouse hover
    const closeTenantMenu = () => {

        setIsMenuOpen(false);
        setTenantAnchorEl(null);
    }

    const selectTenant = async(tenant) => {

        asContext?.tenants?.setSelectedTenant(tenant);
        // getUserName(tenant);
        closeTenantMenu();
        setToken("ASTenantId", tenant?.ASTenantId); //setting TenantId in localStorage
        setSessionStorageItem("ASTenantId", tenant?.ASTenantId)
        removeItem("DataCollectionListFilter");
        let isSubscriptionValid = false
        const currentPath = window.location.pathname;
        if (currentPath.includes('user-permission')) {
            window.location.href = '/users';
        } else if(currentPath.includes('add-connection') && !isSubscriptionValid){
            window.location.href = '/connections';
        } else if(currentPath.includes('add-new-data-collection') && !isSubscriptionValid){
            window.location.href = '/data-collections';
        } else {
            window.location.reload();
        }
    }


    const setTenantList = () => {
        let token = getToken()

        if (token) {

            let decodedToken = jwt(token);

            let tenantList = JSON.parse(decodedToken?.tenants);
            setTenants(tenantList)
        }
    }

    useEffect(() => {
        setTenantList();
    }, [])

    useEffect(() => {
        getSubscription().then((res) => {          
            if (res && res.data) {
                // setLicenseTypeUId(res.data[0].licenseTypeUId); 
                asContext?.subscriptionValidation.setIsSubscriptionExist(res.data[0].asTenantUId !== null && res.data[0].noOfUsers > 0);              
            }
        });
    },[])



    return (
        <>
            <Box sx={{ backgroundColor: "#ffffff", height: "100vh", width: "300px" }} >
                {selectedConnection === null ?
                    <Box sx={{ display: "flex", height: "35px" }}>
                        <AppBar position="static" sx={{ backgroundColor: "#ffffff", borderBottom: "0.1px solid #ccc", boxShadow: "none" }}>
                            <Toolbar sx={{ minHeight: "40px" }}>
                                {isBackIconVisible ? (
                                    <img src={`/assets/back-button.svg`} alt="Back"
                                        className="plugin-back-button" onClick={handleBackClick} />
                                ) : (
                                    <HiMenuAlt1 className="hi-menu-icon" onClick={openTenantMenu}/>
                                )}
                                <Typography sx={{ flexGrow: 1, textAlign: "right" }}
                                    onClick={() => onLogoutUser()}

                                >
                                    <svg className="plugin-asheet-logo">
                                        {ASheetCompleteLogoSvg}
                                    </svg>
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </Box>
                    : ""}
                <PageContent />
            </Box>
            <TenantsMenu
                isOwner={false}
                tenants={tenants}
                selectTenant={selectTenant}
                isMenuOpen={isMenuOpen}
                closeTenantMenu={closeTenantMenu}
                tenantAnchorEl={tenantAnchorEl}
                isDialogOpen={null}
                routeToAddNewOrganization={null}
                handleCloseDialog={null}
                isIndividualUser={null}
                setSavedOrganization={null}
                refresh_token={null}
                remainingTenantFeatureBalance={null}
            />
        </>
    )
}
export default AsheetPluginHeader;