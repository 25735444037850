import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, Menu, MenuItem } from "@mui/material";
import "./add-connection.scss";
import { truncateString } from "../../../shared/code-challanges/code-challanges";
import { MenuIconSvg } from '../../../constants/common-svgs'
import { AsheetPlugInConstant } from "../../../constants/asheet-plugin-constant";

const AddConnection = (props) => {
    const { connectionTypes, usedCloudApplicationsId, handleListView,
        onSelectConnection, showMenuList, closeMenuList, plugInAnchorEl, onMenuClick } = props;

    return (
        <>
            <div className="main-list-heading"> Connect to your data source </div>
            {connectionTypes?.map((item, index) => (
                <List
                    sx={{ width: '100%', maxWidth: 300, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <ListItemButton sx={{ padding: "0px 10px;" }} onClick={() => handleListView(index)}>
                        {item?.showList ? <ExpandLess className="rotated-icon" /> : <ExpandMore/>}
                        <div className="connection-heading" > {item?.heading}</div>
                    </ListItemButton>

                    <Collapse in={item?.showList} timeout="auto" unmountOnExit>
                        {item?.list?.map((list) => (
                            <List component="div" disablePadding >
                                <div className="list-row-view">
                                    <ListItemButton sx={{ pl: 4, py: 1 }} onClick={() => onSelectConnection(list)}>
                                        <img className="connection-image" src={`/assets/${list?.iconImage}`} alt={list?.iconImage} />
                                        <div className="connection-heading">
                                            {truncateString(list?.description, 15)}
                                        </div>
                                        {usedCloudApplicationsId?.has(list?.templateConnectionId) ? <div className="list-icons">
                                            <span className="status-text-span">{"connected"} </span>
                                            <img className="info-icon-logo" src={`/assets/infoIcon.svg`} alt={"InfoIcon"} />
                                            <span className="menu-icon-svg" onClick={(event) => showMenuList(event, list)}>{MenuIconSvg}</span>
                                        </div> : null}
                                    </ListItemButton>
                                </div>
                            </List>
                        ))}
                    </Collapse>
                </List>
            ))}

            <Menu
                anchorEl={plugInAnchorEl}
                open={Boolean(plugInAnchorEl)}
                onClose={closeMenuList}
                className="plugin-menu"
            >
                {AsheetPlugInConstant?.menuItems?.map((item, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => onMenuClick(item?.action)}
                        className="plugin-menu-list"
                    >
                        {item?.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
export default AddConnection;