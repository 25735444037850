import { configureStore } from '@reduxjs/toolkit'
import asSlice from "./slices/as-Slice";
import pluginSlice from './slices/plugin-slice';

export const store = configureStore({
    reducer: {
        asState: asSlice,
        pluginState: pluginSlice

    },
})
