import { HashRouter, useNavigate } from "react-router-dom";
import "./App.scss";
import {
  ASContextProvider,
  useASContext,
} from "./controllers/context-api/as-context";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CustomNavigator from "./controllers/common/custom-navigator/custom-navigator";
import AppRouting from "./app-routing";
import SpinnerContext from "./controllers/common/spinner-context/spinner-context";
import "./styles/common-styles.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { useDispatch } from "react-redux";
import { setIsGoogleSheetPluginActive } from "./store/slices/plugin-slice";
ModuleRegistry.registerModules([ClientSideRowModelModule]);


// global theme css for material ui elements( caution: will effect all the mui elements in the whole app)
const muiTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Poppins",
    },
  },
});

// sets the navigation history object for custom routing
const CustomNavigatorSetter = () => {
  CustomNavigator.navigateHistory = useNavigate();
  return null;
};

// sets the spinner context state manually inside the axios interceptor
const SpinnerContextSetter = () => {
  const asContext = useASContext();
  SpinnerContext.contextHistory = asContext;
  return null;
};


const CheckGoogleSheetPluginStatus = () => {
  const dispatch = useDispatch();
  let isAppRunningInIFrame = window.self !== window.top
  dispatch(setIsGoogleSheetPluginActive(isAppRunningInIFrame));
  return null
};


function App() {

  return (
    <div className="app-container">
      <CheckGoogleSheetPluginStatus />
      <ASContextProvider>
        <ThemeProvider theme={muiTheme}>
          <CustomNavigatorSetter />
          <SpinnerContextSetter />
          <AppRouting />
          <ToastContainer />
        </ThemeProvider>
      </ASContextProvider>
    </div>
  );
}

export default App;
