import { useEffect, useState } from "react";
import { useASContext } from "../../context-api/as-context";
import { AddConnectionComponents, ComponentRendering } from "../../../constants/asheet-plugin-constant";
import AsheetPluginHeader from "../../../components/asheetPlugin/asheet-plugin-header/asheet-plugin-header";
import { useNavigate } from "react-router-dom";

const AsheetPlugInHeader = () => {
    const navigate = useNavigate();
    const asContext = useASContext();
    const [isBackIconVisible, setIsBackIconVisible] = useState(false);
    const selectedConnection = asContext?.plugin?.connectionSelected;

    useEffect(() => {
        if (asContext?.asheetPlugin?.renderComponent === null) {
            setIsBackIconVisible(false);
        }
        else {
            setIsBackIconVisible(true);
        }
    }, [asContext?.asheetPlugin?.renderComponent])

    const renderComponents = (fieldToShow) => {
        const newState = new ComponentRendering();

        if (newState?.hasOwnProperty(fieldToShow)) {
            newState[fieldToShow] = true;
        }
        asContext?.asheetPlugin?.setRenderComponent(newState);
    };

    const handleBackClick = () => {
        if (asContext?.asheetPlugin?.renderComponent[AddConnectionComponents?.ADD_CONNECTION] === true) {
            renderComponents();
            asContext?.asheetPlugin?.setRenderComponent(null);
            navigate("/plugin");
        }
        else if (asContext?.asheetPlugin?.renderComponent[AddConnectionComponents?.CONNECT_VIEW] === true || asContext?.asheetPlugin?.renderComponent[AddConnectionComponents?.SUCCESSFULL_CONNECTED] === true) {
            renderComponents(AddConnectionComponents?.ADD_CONNECTION);
        }
    }

    return (
        <>
            <AsheetPluginHeader handleBackClick={handleBackClick}
                isBackIconVisible={isBackIconVisible}
                selectedConnection={selectedConnection} />
        </>
    );
}
export default AsheetPlugInHeader;