import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ServerNameEnum } from "../../../constants/server-name-constants";
import { getDataSourceType } from "../../../services/data-source-type.service";
import {
  addDataSourceType,
  getDataSourceByUID,
  testDataSourceType,
  updateDataSourceType,
  disconnetCloudConnection

} from "../../../services/data-source.service";
import * as connectionService from '../../../services/data-source.service';
import { ASStepper } from "../../../shared/stepper/as-stepper";
import { ConnectionDetails } from "../../../components/connections/connection-details/connection-details";
import { ConnectionType } from "../../../components/connections/connection-type/connection-type";
import { TestConnection } from "../../../components/connections/test-connection/test-connection";
import "./add-new-connection.scss";

import { refineFormData } from "../../../services/data-source.utility.service";
import MuiDialog from "../../../shared/mui-dialog/mui-dialog";
import { RestServiceConstants } from "../../../constants/rest-service-constant";
import { useLocation } from "react-router-dom";
 
import * as collectionService from '../../../services/data-collection.service';
import { generateCode, generateRandomString } from "../../../shared/code-challanges/code-challanges";
import { SaveConnection } from "../../../components/connections/connection-details/save-template-connection/saveTemplateConnection";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isNullOrEmpty } from "../../../services/data-source-validation";
import { SaveConectionDetails } from "../connection-details/save-connection-details";
import { ConnectionStepLabelConstant } from "../../../constants/as-stepper-step-label";
import { getTokenFromAuthCode } from "../../../services/authCallback-service";
import { useASContext } from "../../context-api/as-context";
import { AppEnum } from "../../../constants/app-enum";

export function AddNewConnection() {
  let navigate = useNavigate();
  const location = useLocation();
  const dataSources = location.state?.dataSources;
  const [formData, setSaveFormData] = useState();
  const [selectedStep, handleSelectedStep] = useState(null);
  const { datasourceId: id } = useParams();
  const [activeStep, setActiveStep] = useState(id ? 1 : 0);
  const [isConnectionDetailDisabled, setIsConnectionDetailDisabled] =
    useState(true);
  const [isTestingConnection, setIsTestingConnection] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [sourceType, setSourceType] = useState([]);
  const [isShowPopUp, setIsShowPopUp] = useState(false);
  const [code, setCode] = useState(null);
  const [activeStatus, setActiveStatus] = useState(false);
  const [templateConnectionTypeId, setTemplateConnectionTypeId] = useState(0);
  const [isTemplateConnection, setIsTemplateConnection] = useState(false);
  const [isUpdatingTemplateConnection, setIsUpdatingTemplateConnection] = useState(false);
  const [checkInheritedConnectionValid, setCheckInheritedConnectionValid] = useState(false);
  const [isCLoudConnectionDisconnected, setIsCLoudConnectionDisconnected] = useState(false);
  const [stepperViewFromConnection,setStepperViewFromConnection] = useState(true);
  const [usedCloudApplicationsId,setUsedCloudApplicationsId] = useState(new Set());

  const [isClientDataUpdated,setIsClientDataUpdated] = useState(false);
  const [existingClientData,setExistingClientData] = useState({
      clientId : "",
      clientSecret : "",
      scope : "",
      supportRefreshToken : ""
  })
  const [isTallyConnector,setIsTallyConnector] = useState(false);

  const [initialConnectionName, setInitialConnectionName] = useState("");
  const [connectionNameExists, setConnectionNameExists] = useState(true);
  const [previousSelectedStepIds,setPreviouSelectedStepIds]=useState([]); 
  const [oauthState,setOauthState]=useState();
  const [checkAuthCode,setCheckAuthCode] = useState(false); 
  const [intervalCount, setIntervalCount] = useState(0);
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);
  const [isDialogOpen,setIsDialogOpen] = useState(false);
  const maxIntervalCount = 40;
  
  const windowRef = useRef();

  const [restOrCloudApplicationsId,setRestOrCloudApplicationsId] = useState(new Set());
  const asContext = useASContext();

  useEffect(()=>{
      if(!(asContext.subscriptionValidation.isSubscriptionExist)){
          navigate("/connections");
      }
  },[asContext.subscriptionValidation.isSubscriptionExist]);

  useEffect(()=>{
    if(dataSources && dataSources.length > 0){
      setUsedCloudApplicationsId(
        new Set(dataSources.map(ca => ca.templateConnectionId).filter(tcid => tcid!=null)));
    }
  },[dataSources]);

  useEffect(()=>{
    if(activeStep === 0 && selectedStep != null){
      setPreviouSelectedStepIds((previousSelectedStepIds) =>{
        return [...previousSelectedStepIds,selectedStep.typeName];
      });
    }

    if(selectedStep?.id == AppEnum?.SysConnnectionTypeId?.Tally_Connection){
      setIsTallyConnector(true);
    }
    else if(isTallyConnector && selectedStep?.id != AppEnum?.SysConnnectionTypeId?.Tally_Connection && !formData?.uID?.value){
      setIsTallyConnector(false);
    }
  },[selectedStep]);

  useEffect(() => {
    let name = formData?.name?.value.toLowerCase();
    let connectionName = initialConnectionName?.toLowerCase();

    if(connectionName === name){
      setConnectionNameExists(false);
    }
    if (formData?.name?.value && initialConnectionName !== formData?.name?.value) {
      if (name !== initialConnectionName?.toLowerCase() && activeStep === steps.length-1) {
        const getConnectionByName = setTimeout(() => {
            connectionService.getConnectionByName(formData.name.value)
                .then((response) => {
                    if (response.data) {
                      toast.error("Connection with this name already exists");
                      setConnectionNameExists(true);
                    }
                    else{
                      setConnectionNameExists(false);
                      setErrorMessage("");
                    }
                });
        }, 500);

        return () => clearTimeout(getConnectionByName);
    }
  }
  }, [formData?.name?.value,activeStep])

  useEffect(() => {
    getDataSourceType().then((res) => {
      if (res.data) {
        const _sources = res?.data?.filter(datasource => datasource.isActive === true)
        .map((datasource, index) => ({
          ...datasource,
          key: Date.now() + index,
        }));

        setSourceType(_sources);
        setRestOrCloudApplicationsId(
          new Set(_sources.filter(source => source.baseType === 'REST').map(connection => connection.id))
        );

        if (id) {
          getDataSourceByUID(id).then((res) => {
            const source = _sources.find(
              // (_source) => _source.datasourceTypeName === res.dataSourceTypeName
              (_source) => _source.typeName === res.data.connectionTypeName
            );

            if (source) {
              setFormData(res.data, id);
              if (res.data.baseConnectionTypeId){
                source.id = res.data.baseConnectionTypeId
                if(res?.data?.connectionTypeId == AppEnum?.SysConnnectionTypeId?.Tally_Connection){
                  setIsTallyConnector(true);
                }
                //source.templateConnectionId = null;
                setIsUpdatingTemplateConnection(true);

                //check if connection is disconnected
                if(res?.data?.parameters){
                  const parameters = res?.data?.parameters;
                  let refreshToken = parameters.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_REFRESHTOKEN)[0]?.parameterValue;
                  let isSupportRefreshToken = parameters.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN)[0]?.parameterValue;
                  let accessToken = parameters.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN)[0]?.parameterValue;
                  let accessTokenLastModified = parameters.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN)[0]?.modifiedDateTime;
                  if(isSupportRefreshToken == 'false' && !isNullOrEmpty(accessToken)){
                    let accesstokenExpireTime = parameters.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_EXPIRESAT)[0]?.parameterValue;
                    const issueTime = new Date(accessTokenLastModified);
                    const expirationTime = new Date(issueTime.getTime() + accesstokenExpireTime * 1000);

                    const currentTime = new Date();
                    if (currentTime > expirationTime) {
                      setIsCLoudConnectionDisconnected(true);
                    }
                  }
                  else if(isNullOrEmpty(accessToken)){
                    setIsCLoudConnectionDisconnected(true);
                  }
                  else if(isNullOrEmpty(refreshToken)){
                    setIsCLoudConnectionDisconnected(true);
                  }
                }
              }
              handleSelectedStep(source);
              setActiveStep(1);
              setActiveStatus(res.data.isActive)
              setInitialConnectionName(res?.data?.connectionName);
            }
          });
        }
      }
    });
  }, [id]);

  useEffect(() => {
    if(isUpdatingTemplateConnection){
      let refreshToken = formData[RestServiceConstants?.OAUTH2_INPUTS.ACCESS_TOKEN_REFRESHTOKEN]?.value;
      if(isNullOrEmpty(refreshToken)){
        setIsCLoudConnectionDisconnected(true);
      }
    }
  },[isUpdatingTemplateConnection,formData]);

  useEffect(() => {
    if(!isTemplateConnection && (formData && formData[RestServiceConstants.REQUIRE_PROXY_CONNECTOR]?.value == "true")){
      setIsTallyConnector(true);
    }
  },[formData])

  useEffect(() => {
    if (selectedStep) {
      if (selectedStep.templateConnectionId) {
        setTemplateConnectionTypeId(selectedStep.id);
        setIsTemplateConnection(true);
      }
      else{
        setTemplateConnectionTypeId(0);
        setIsTemplateConnection(false);
      }
    }
  }, [selectedStep]);

  useEffect(() => {
    if((existingClientData.clientId == "" || existingClientData.clientSecret == "" || existingClientData.scope == "") && !isNullOrEmpty(id) && formData){
      setExistingClientData({
          scope : (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.SCOPE] && formData[RestServiceConstants.OAUTH2_INPUTS.SCOPE]).value,
          clientId : (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID] && formData[RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID]).value,
          clientSecret : (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.CLIENT_SECRET] && formData[RestServiceConstants.OAUTH2_INPUTS.CLIENT_SECRET]).value,
          supportRefreshToken : (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN] && formData[RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN]).value
      })
    }
  },[formData])

  const setFormData = (res, id, isTemplateParameter = false) => {
    let newFormData = null;

    if(isTemplateParameter){
      newFormData = {
        name: formData?.name,
        uID: formData?.uID
      };
    }
    else{
      newFormData = {
        name: { value: res.connectionName },
        uID: { value: id },
      };
    }

    res.parameters.forEach((ele) => {
      newFormData[ele.parameterName] = {
        value: ele.parameterValue,
      };
    });
    if(!isTemplateConnection){
      setInitialConnectionName(newFormData?.name?.value);
    }
    setSaveFormData(newFormData);
    setCheckInheritedConnectionValid(false);
  };

  const openSecretDialog = () => {
    setIsDialogOpen(true);
  }

  const handleSave = () => {
    setErrorMessage(null);
    const data = refineFormData(formData, selectedStep, restOrCloudApplicationsId, isTallyConnector);

    if (isTemplateConnection || isUpdatingTemplateConnection){
      data.sysConnectionTypeId = templateConnectionTypeId;
      data.baseConnectionTypeId = restOrCloudApplicationsId.has(selectedStep.id) ? 2 : selectedStep.id;
      data.templateConnectionId = selectedStep?.templateConnectionId;
      //data.parameters = [];
      if(data?.name == null || data?.name == ""){
        return
      }
    }


    

    if((
      !!formData &&
      !!formData[RestServiceConstants.OAUTH2_INPUTS.AUTHORIZATION_HEADER_NAME] &&
      formData[RestServiceConstants.OAUTH2_INPUTS.AUTHORIZATION_HEADER_NAME]
    ).value == ""){
      formData[RestServiceConstants.OAUTH2_INPUTS.AUTHORIZATION_HEADER_NAME].value = "Authorization";
    }

    (!!data.uId ? updateDataSourceType(data) : addDataSourceType(data))
      .then((_res) => {
        if (_res.data) {
          navigate("/connections");
          toast.success(data.uId ? "Connection updated successfully." : "Connection added successfully.")
        }
        else if(_res.hasError){
          toast.error(_res.errorMessage);
        }
      })
      .catch((err) => {
        validateMsg(err.validationErrors);
        toast.error(err.validationErrors);
      });
  };

  const validateMsg = (err) => {
    const _formData = { ...formData };

    err.forEach((_v) => {
      formData[_v.memberNames[0]].errorMsg = _v.errorMessage;
      formData[_v.memberNames[0]].isValid = false;
    });

    setSaveFormData(_formData);
    setActiveStep(1);
  };

  const closeDialog = () => {
    setIsShowPopUp(false);
  };

  //delete connection
  const deleteConnection = () => {
    setIsShowPopUp(true);
    if (isShowPopUp) {
      connectionService.deleteDataSource(id).then((response) => {
        if (!response.hasError) {
          navigate("/connections");
          setIsShowPopUp(false);
          toast.success("Connection deleted successfully.");
        } else {
          if (response){
            setErrorMessage(response?.errorMessage);
          } 
          else {
            setErrorMessage(`Couldn't delete this connection!`);
          }
          setIsShowPopUp(false);
          toast.error(response?.errorMessage);
        }
      });
    }
  };

  const updateActiveStatus = (event) => {

    setActiveStatus(event.target.checked)
    setErrorMessage(null);

    const model = {
      uId : id,
      isActive : event.target.checked,
      fieldsToUpdate : ["IsActive"]
    }; 

    updateDataSourceType(model)
      .then((_res) => {
        
      })
      .catch((err) => validateMsg(err.validationErrors));

  };

  const disconnectConnection = () => {
    disconnetCloudConnection(id).then((res) => {
      if(!res.hasError){
        formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_REFRESHTOKEN].value = "";
        toast.success("Connection Disconnected Successfully.");
        setErrorMessage("The connection was disconnected successfully.");
        setIsCLoudConnectionDisconnected(true);
      }
    })
  }


  useEffect(() => {
    const receiveMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const receivedData = event.data;
      if (receivedData && receivedData.code) {
        setCode(receivedData.code);
      }
    };

    window.addEventListener('message', receiveMessage);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  useEffect(() => {
    if (code != null) {
      testConnection(code);
    }
  }, [code])

  // checks the connection if its working fine or not
  const testConnection = async () => {

    var grantType = (
                      !!formData &&
                      !!formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE] &&
                      formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE]
                    ).value;
    
    var refresh_token =  (
                            !!formData &&
                            !!formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_REFRESHTOKEN] &&
                            formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_REFRESHTOKEN]
                          ).value;
    
    var uId =  (
                 !!formData &&
                 !!formData["uID"] &&
                 formData["uID"]
               ).value;
    
    var supportRefreshToken = (
                                !!formData &&
                                !!formData[RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN] &&
                                formData[RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN]
                              ).value

    var is_supportRefreshToken = supportRefreshToken == "" ? false : supportRefreshToken == "false" ? false : true;

    var isNullOrEmptyUId = (uId == null || uId == '') ? true : false;
    var isNullOrEmptyRefreshToken = (refresh_token == null || refresh_token == '') ? true : false;
    var isGrantTypeForOauth = (grantType == "authorization_code" || grantType == "authorization_code_with_pkce") ? true : false;

    setIsLimitExceeded(false);

    if ((isGrantTypeForOauth && code == null && isNullOrEmptyRefreshToken && is_supportRefreshToken == false) || (isGrantTypeForOauth && code == null && isNullOrEmptyRefreshToken) || (isNullOrEmptyUId && code == null && isGrantTypeForOauth) || (is_supportRefreshToken == false && code == null && isGrantTypeForOauth) || (isClientDataUpdated && code == null)) 
      {

        var data = null;
        var OauthNewState = generateRandomString(50);
        setOauthState(OauthNewState);
        if(grantType == "authorization_code"){
          data = {
            client_id: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID] && formData[RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID]).value,
            redirect_uri: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.CALLBACK_URL] && formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.CALLBACK_URL]).value,
            auth_url: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.AUTH_URL] && formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.AUTH_URL]).value,
            scope: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.SCOPE] && formData[RestServiceConstants.OAUTH2_INPUTS.SCOPE]).value,
            state: OauthNewState
          };
        }
        else if(grantType == "authorization_code_with_pkce"){
          var pkceData = await generateCode(OauthNewState);

          formData[RestServiceConstants.OAUTH2_INPUTS.CODE_VERIFIER].value = pkceData.verifier;
      
          console.log(pkceData);

          data = {
            responseType: "code",
            auth_url: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.AUTH_URL] && formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.AUTH_URL]).value,
            client_id: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID] && formData[RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID]).value,
            scope: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.SCOPE] && formData[RestServiceConstants.OAUTH2_INPUTS.SCOPE]).value,
            redirect_uri: (!!formData && !!formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.CALLBACK_URL] && formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.CALLBACK_URL]).value,
            state: OauthNewState
          };
          if(formData[RestServiceConstants.OAUTH2_INPUTS.CODE_CHALLENGE_METHOD].value != "S256"){
            data["code_challenge"]= formData[RestServiceConstants.OAUTH2_INPUTS.CODE_VERIFIER].value
          }
          else{
            data["code_challenge"]= pkceData.base64EncodedHash
            data["code_challenge_method"]=formData[RestServiceConstants.OAUTH2_INPUTS.CODE_CHALLENGE_METHOD].value;
          }
        }

        if(formData[RestServiceConstants.OAUTH2_INPUTS.PARAMETERS.AUTH_REQUEST].value != null || formData[RestServiceConstants.OAUTH2_INPUTS.PARAMETERS.AUTH_REQUEST].value != '[]' || formData[RestServiceConstants.OAUTH2_INPUTS.PARAMETERS.AUTH_REQUEST].value != ''){
          var variableList = await collectionService.getVariableList();
          let varData = null;
          if(variableList.data && variableList.data.length > 0){
            varData = variableList.data;
          }
          let authRequestData = [];

          if(formData[RestServiceConstants.OAUTH2_INPUTS.PARAMETERS.AUTH_REQUEST].value){
            authRequestData = JSON.parse(formData[RestServiceConstants.OAUTH2_INPUTS.PARAMETERS.AUTH_REQUEST].value);
          }
  
          authRequestData.forEach(obj => {
            if(varData != null && varData.length >0){
              var selectedVar = variableList.data.filter(i => i.uId == obj.value);
              if(selectedVar.length > 0) {
                data[obj.key] = selectedVar[0].primitiveTypeValue;
              }
              else{
                data[obj.key] = obj.value;
              }
            }
            else{
              data[obj.key] = obj.value;
            }
          });
        }

        setCheckAuthCode(true);

        const queryString = Object.keys(data)
          .map(
            (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
          )
          .join("&");
          const popupWindow = window.open(
            `/begin-oauth?${queryString}`,
            "_blank",
            "width=600,height=400"
          );

          
        windowRef.current = popupWindow;

      } 
      else {
        if (code != null) {
          formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE] = {
            isValid: true,
            datasourceParameterTypeId: 2016,
            value: is_supportRefreshToken ? refresh_token ? "" : code : code,
          };
        }
        else{
          if(grantType == "authorization_code" || grantType == "authorization_code_with_pkce"){
            formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE] = {
              isValid: true,
              datasourceParameterTypeId: 2016,
              value: formData[RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN].value == "false" ? "" : "",
            };
          }
        }

        if(isClientDataUpdated){
          formData[RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN] = {
            isValid: true,
            datasourceParameterTypeId: formData[RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN].datasourceParameterTypeId,
            value: "false"
          };
        }

        setIsTestingConnection(true);
        setErrorMessage(null);





        

        const requireProxyConnector = formData && formData[RestServiceConstants?.REQUIRE_PROXY_CONNECTOR]?.value;
        if(is_supportRefreshToken || grantType == "client_credentials" || isTallyConnector || (requireProxyConnector ==  'true' || requireProxyConnector == true)){
          testDataSourceType(refineFormData(formData, selectedStep, restOrCloudApplicationsId)).then(

          (_res) => {
            setCode(null);
            if (_res.data && !_res?.data?.hasError && _res?.data?.error == null && (isTallyConnector == false || (isTallyConnector && (!_res?.data?.data?.HasError ? _res?.data?.data?.HasError == false : _res?.data?.data?.successful == true)))) {
              setIsTestingConnection(false);

              if(isClientDataUpdated){
                setIsClientDataUpdated(false);
                formData[RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN] = {
                  isValid: true,
                  datasourceParameterTypeId: formData[RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN].datasourceParameterTypeId,
                  value: existingClientData.supportRefreshToken
                };
              }

              if ((
                !!formData &&
                !!formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE] &&
                formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE]
              ).value == "authorization_code" || (
                !!formData &&
                !!formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE] &&
                formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE]
              ).value == "authorization_code_with_pkce") {
                formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN] = {
                  isValid: true,
                  datasourceParameterTypeId: 2017,
                  value: _res?.data?.data?.access_token,
                };
                if(_res?.data?.data?.expires_in){
                  formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_EXPIRESAT] = {
                    isValid: true,
                    datasourceParameterTypeId: 2020,
                    value: _res?.data?.data?.expires_in,
                  };
                }
                if(_res?.data?.data?.refresh_token){
                  formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_REFRESHTOKEN] = {
                    isValid: true,
                    datasourceParameterTypeId: 2025,
                    value: _res?.data?.data?.refresh_token,
                  };
                }
              }

              if(isCLoudConnectionDisconnected){
                setIsCLoudConnectionDisconnected(false);
              }

              setErrorMessage("The connection was established successfully.");
            } else {
              if (_res?.hasError || _res?.data?.error || _res?.data?.data?.error || _res?.data?.data?.errorMessage || _res?.data?.data?.ErrorMessage){
                if(isTallyConnector){
                  setErrorMessage(
                    _res?.data?.data?.errorMessage ? _res?.data?.data?.errorMessage : _res?.data?.data?.ErrorMessage ? _res?.data?.data?.ErrorMessage 
                            : _res?.errorMessage? _res?.errorMessage : _res?.data?.error ? _res?.data?.error: "Couldn't establish a secure connection!");
                }
                else{
                  setErrorMessage(
                    _res?.errorMessage
                      ? _res?.errorMessage
                      : _res?.data?.data?.error
                        ? _res?.data?.data?.error
                        : _res?.data?.error
                          ? _res?.data?.error
                                : "Couldn't establish a secure connection!"
                  );
                }
              }
              else setErrorMessage(`Couldn't establish a secure connection!`);

              setIsTestingConnection(false);
            }
          }
        );
        }
        
    }
    // .catch((error) => {

    //     if (error?.error)
    //         setErrorMessage(error?.error);
    //     else
    //         setErrorMessage(`Couldn't establish a secure connection!`);

    //     setIsTestingConnection(false);
    // })
  };


  useEffect(() => {
    const interval = setInterval(() => {
      if (checkAuthCode) {
        formData[RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE] = {
          isValid: true,
          datasourceParameterTypeId: 2016,
          value: ""
        };
        authCode(refineFormData(formData, selectedStep, restOrCloudApplicationsId,false,true));
        if(isLimitExceeded == false){
          setIntervalCount((prevCount) => prevCount + 1);
        }
        else{
          clearInterval(interval);
        }
      }
    }, 3000);

    if (intervalCount >= maxIntervalCount) {
      clearInterval(interval);
      setIntervalCount(0);
      setIsLimitExceeded(true);
      setCheckAuthCode(false)
    }

    return () => clearInterval(interval);
  }, [checkAuthCode, intervalCount]);
 
  const authCode = async (model) => {
    try {
      const _res = await getTokenFromAuthCode(oauthState, model);
      if (_res.data && !_res?.data?.hasError && _res?.data?.error == null) {
        setIsTestingConnection(false);
        setCheckAuthCode(false);

        if(isClientDataUpdated){
          setIsClientDataUpdated(false);
          formData[RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN] = {
            isValid: true,
            datasourceParameterTypeId: formData[RestServiceConstants.OAUTH2_INPUTS.SUPPORT_REFRESH_TOKEN].datasourceParameterTypeId,
            value: existingClientData.supportRefreshToken
          };
        }

        if ((
          !!formData &&
          !!formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE] &&
          formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE]
        ).value == "authorization_code" || (
          !!formData &&
          !!formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE] &&
          formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE]
        ).value == "authorization_code_with_pkce") {
          formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN] = {
            isValid: true,
            datasourceParameterTypeId: 2017,
            value: _res?.data?.data?.access_token,
          };
          if(_res?.data?.data?.expires_in){
            formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_EXPIRESAT] = {
              isValid: true,
              datasourceParameterTypeId: 2020,
              value: _res?.data?.data?.expires_in,
            };
          }
          if(_res?.data?.data?.refresh_token){
            formData[RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_REFRESHTOKEN] = {
              isValid: true,
              datasourceParameterTypeId: 2025,
              value: _res?.data?.data?.refresh_token,
            };
          }
        }

        if(isCLoudConnectionDisconnected){
          setIsCLoudConnectionDisconnected(false);
        }
        setErrorMessage("The connection was established successfully.");
      } else {
        
        if (_res?.hasError || _res?.data?.error || _res?.data?.data?.error)
          setErrorMessage(
            _res?.errorMessage
              ? _res?.errorMessage
              : _res?.data?.data?.error
                ? _res?.data?.data?.error
                : _res?.data?.error
                  ? _res?.data?.error
                  : "Couldn't establish a secure connection!"
          );
        else setErrorMessage(`Couldn't establish a secure connection!`);

        setIsTestingConnection(false);
        if (_res?.data?.hasError) {
          checkIfWindowOpen();
        }
      }
    } catch (error) {
    }
  }

  const checkIfWindowOpen = () => {
    if (windowRef?.current && !windowRef?.current?.closed) {
    } else {
      setCheckAuthCode(false);
      setErrorMessage(`Please try again!`);
    }
  };

  const getServerName = () => {
    const key = ServerNameEnum.map.get(selectedStep.id);
    return key && formData[key]?.value;
  };


  let steps = [
    {
      label: ConnectionStepLabelConstant.Select_The_Type_Of_Connection,
      description: (
        <ConnectionType
          sourceType={sourceType}
          selectedStep={selectedStep}
          selectedType={handleSelectedStep}
          usedCloudApplicationsId={usedCloudApplicationsId}
        />
      ),
      optionalComponent: {
        // image: selectedStep?.datasourceTypeIconImage,
        // label: !!selectedStep ? selectedStep.datasourceTypeName : "",
        image: selectedStep?.iconImage,
        label: !!selectedStep ? selectedStep.description : "",
      },
      disabled: !selectedStep,
      isShowDeleteButton: id ? true : false,
      isOpenDialoge: true,
    },
    {
      label: ConnectionStepLabelConstant.Connection_Detail,
      description: (
        <ConnectionDetails
          setSaveFormData={setSaveFormData}
          formData={formData}
          isFormInvalid={setIsConnectionDetailDisabled}
          selectedStep={selectedStep}
          setFormData={setFormData}
          isTemplateConnection={isTemplateConnection}
          isUpdatingTemplateConnection={isUpdatingTemplateConnection}
          errorMessage={errorMessage}
          testConnection={testConnection}
          disconnectConnection={disconnectConnection}
          isCLoudConnectionDisconnected={isCLoudConnectionDisconnected}
          existingClientData={existingClientData}
          setIsClientDataUpdated={setIsClientDataUpdated}
          isTally={isTallyConnector}
          restOrCloudApplicationsId={restOrCloudApplicationsId}
        />
      ),
      optionalComponent: null,
      disabled: (isTallyConnector ? false : isConnectionDetailDisabled || ((isTemplateConnection || isUpdatingTemplateConnection) ? isCLoudConnectionDisconnected ? true : false : false)),
      disabledBack: !!formData?.uID && !!formData?.uID?.value,
    },
    {
      label: (isTemplateConnection || isUpdatingTemplateConnection) && isTallyConnector == false ? ConnectionStepLabelConstant.Save_Connection : ConnectionStepLabelConstant.Test_Connection,
      description: ((isTemplateConnection || isUpdatingTemplateConnection) && isTallyConnector == false ? (
        <SaveConnection formData={formData} setSaveFormData={setSaveFormData} setCheckInheritedConnectionValid={setCheckInheritedConnectionValid}/>
      ) : (
        <TestConnection
          testConnection={testConnection}
          isTestingConnection={isTestingConnection}
          //dataSourceName={selectedStep ? selectedStep.datasourceTypeName : ""}
          dataSourceName={selectedStep ? selectedStep.description : ""}
          formData={formData}
          serverName={getServerName}
          selectedStep={selectedStep}
          errorMessage={errorMessage}
          isTally={isTallyConnector}
          isDialogOpen={isDialogOpen}
          openSecretDialog={openSecretDialog}
          setIsDialogOpen={setIsDialogOpen}
        />)
      ),
      optionalComponent: null,

      disabled: (isTemplateConnection || isUpdatingTemplateConnection) ? isTallyConnector ? false : checkInheritedConnectionValid ? connectionNameExists : connectionNameExists : false,
    },
  ];

  if(!isTemplateConnection || isTallyConnector == true){
    steps.push( {
      label: ConnectionStepLabelConstant.Save_Connection_Details,
      description:(
        <SaveConectionDetails formData={formData} setSaveFormData={setSaveFormData}/>
      ),
      optionalComponent: null,
      disabled: connectionNameExists,
      // isShowDeleteButton: id ? true : false,
      // isOpenDialoge: true,
    });
  }

  return (
    <Fragment>
      <MuiDialog
        isShowPopUp={isShowPopUp}
        secondaryButtonAction={closeDialog}
        primaryButtonAction={deleteConnection}
        closeDialog={closeDialog}
        secondaryButtonTitle="No"
        primaryButtonTitle="Yes"
        titleDescription="Are you sure you want to delete this connection?"
      />

      <ASStepper
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        save={handleSave}
        steps={steps}
        uId={id}
        deleteConnection={deleteConnection}
        isTestingConnection={isTestingConnection}
        setErrorMessage={setErrorMessage}
        isConnection={true}
        updateActiveStatus={updateActiveStatus}
        activeStatus={activeStatus}
        stepperViewFromConnection={stepperViewFromConnection}
        setSaveFormData={setSaveFormData}
        previousSelectedStepIds={previousSelectedStepIds}
        setPreviouSelectedStepIds={setPreviouSelectedStepIds}
      />
    </Fragment>
  );
}
