import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import {
    ASheetCompleteLogoSvg, GoogleIconSvg, TwitterIconSvg, LinkdinIconSvg,
    GithubIconSvg,
    MicrosoftIconSvg
} from "../../constants/common-svgs";
import { ASRadioGroup } from "../../shared/as-radio-group";
import { ASTextField } from "../../shared/as-text-fields";
import "./login-signup.scss";
import { useASContext } from "../../controllers/context-api/as-context";
import MessageToaster from "../../shared/message-toaster/message-toaster";
import { handleSocialSignIn } from "../../services/login-signup.service";
import { useSelector } from "react-redux";



export function LoginSignUp(props) {

    const isGoogleSheetPluginActive = useSelector((state) => state.pluginState?.isGoogleSheetPluginActive);


    return (
        <div className="login-signup-container">

            <div className="columns column-1">
                <img src="./assets/login.svg" alt="LoginBanner" />
            </div>

            <div className={`${props.pFlag === "signup" ? "columns column-2" : "columns column-2 paper-container"}`}>
                <Paper elevation={3} className={`form-container ${isGoogleSheetPluginActive ? 'plugin-view' : ''}`}>
                    {selectView({ ...props, isGoogleSheetPluginActive: isGoogleSheetPluginActive })}
                </Paper>
            </div>

        </div>
    );
}

function selectView(props) {

    switch (props.pFlag) {

        case "signup":
            return _signUpForm(props);
        case "forgotpassword":
            return forgotPassword(props);
        case "resetpassword":
            return resetPassword(props);
        case "login":
        default:
            return loginForm(props);

    }
}



export function FormHeader(formName, isForm) {

    const asContext = useASContext();

    return (
        <div className="login-form-header">

            {
                asContext?.spinner?.isDataLoading && isForm &&
                <div className="as-form-spinner"></div>
            }

            <div className="header-item header-item-1">{formName}</div>

            <div className="header-item header-item-2">
                {ASheetCompleteLogoSvg}
            </div>

        </div>
    );
}



function FormFooter(footerText1, footerText2, link, props) {

    const onLoginWithGoogleOAuth = (url) => {
        const left = (window.screen.width / 2) - 300;
        const top = (window.screen.height / 2) - 300;

        const authWindow = window.open(url, 'Google Auth', `width=${500},height=${600},top=${top},left=${left}`);
        authWindow.focus();

        const checkPopup = setInterval(() => {
            try {
                if (authWindow.location.href) {
                    const popupUrl = new URL(authWindow.location.href);

                    const token = popupUrl.searchParams.get('token');
                    const refreshToken = popupUrl.searchParams.get('refresh_token');

                    if (token && refreshToken) {
                        authWindow.close();
                        clearInterval(checkPopup);
                        const returnUrl = `${window.location.origin}/login-jwt-auth?token=${token}&refresh_token=${refreshToken}`;
                        window.location.href = returnUrl;
                    }
                }
            }
            catch (error) {
                console.error(error);
            }

            if (authWindow.closed)
                clearInterval(checkPopup);
        }, 100);
    }


    return (
        <div className="footer">

            <label>Or</label>


            {
                <div className="social-login-div">

                    <label className="or-label">{footerText1}</label>

                    <Link
                        onClick={() => {
                            const url = handleSocialSignIn("Google");

                            if (props.isGoogleSheetPluginActive)
                                onLoginWithGoogleOAuth(url)
                            else
                                window.open(url, "_self");
                        }}
                    >
                        <img src="/assets/googleLogo.svg" alt="googleLogo" />
                    </Link>


                    {/* <i className="icon">
                    {TwitterIconSvg}
                </i>

                <i className="icon">
                    {GithubIconSvg}
                </i>

                <i className="icon">
                    {LinkdinIconSvg}
                </i> */}

                </div>
            }

            <div className="footer-text">
                <Link className="link footer-link" to={`/${link}`}>
                    <label onClick={props.resetFormState} className="login-signup-link">{footerText2}
                        <span >{link === 'signup' ? ' Sign Up here' : 'Login here'}</span></label>
                </Link>
            </div>

        </div >
    );
}

function loginForm(props) {

    const { loginModel } = props;

    return (
        <div className="login-form-container">
            <form>
                <div className="login-form-inputs">

                    {FormHeader("Login", true)}

                    {
                        loginModel.errorMessage &&
                        <MessageToaster errorMessage={loginModel.errorMessage} />
                    }

                    <div>
                        <ASTextField
                            className="text-field"
                            fullWidth
                            label="Email"
                            tabIndex={0}
                            key="Email"
                            variant="outlined"
                            onChange={props.onChangeEmailHandler}
                            onBlur={props.onBlurEmailHandler}
                        />

                        {loginModel.isEmailValid ? '' : <label className="invalid-field">Email is invalid</label>}


                    </div>

                    <div>

                        <Link className="forgot-password-link" to={`/forgotpassword`} tabIndex={-1}>
                            Forgot Password
                        </Link>
                        <ASTextField
                            className="password-text-field"
                            type={loginModel.isPasswordVisible ? "text" : "password"}
                            fullWidth
                            label="Password"
                            variant="outlined"
                            tabIndex={1}
                            onChange={props.onChangePasswordHandler}
                        />

                        <span className="eye-icon eye-icon-override">
                            {
                                loginModel.isPasswordVisible ?
                                    <VisibilityOff onClick={props.showHidePassword} />
                                    :
                                    <VisibilityIcon onClick={props.showHidePassword} />
                            }
                        </span>
                    </div>

                    <div className="login-button login-button-override">

                        <Button className="button" variant="contained" color="error"
                            onClick={props.login} type="submit"
                        >
                            Login
                        </Button>

                    </div>

                </div>
            </form>

            {FormFooter(
                `Login With`,
                `Don't have an account? `,
                "signup", props
            )}

        </div>
    );
}

function _signUpForm(props) {

    // const [accountFlag, setAccountFlag] = useState(true);

    const { signupModel } = props;

    const radioGroup = {
        name: "Business Types",
        defaultValue: signupModel.isIndividual,
        radioButtons: [
            {
                value: true, label: "Individual",
                // onChange: () => props.onCheckBusinessAccountHandler(true)
            },
            {
                value: false, label: "Business Account",
                // onChange: () => props.onCheckBusinessAccountHandler(false)
            },
        ],
    };

    return (
        <div className="login-form-container">
            <form>
                <div className="login-form-inputs">

                    {FormHeader("Sign Up", true)}

                    {
                        signupModel.errorMessage &&
                        <MessageToaster
                            errorMessage={signupModel.errorMessage}
                        />
                    }

                    <div>
                        <ASTextField
                            className="text-field"
                            fullWidth
                            label="Name"
                            key="Name"
                            variant="outlined"
                            onChange={props.onChangeFullnameHandler}
                            onBlur={props.onBlurFullnameHandler}
                            tabIndex={0}
                        />
                    </div>

                    {signupModel.isFullnameValid ? '' : <label className="invalid-field">Full name is invalid</label>}

                    <ASTextField
                        className="text-field"
                        fullWidth
                        label="Email"
                        variant="outlined"
                        onChange={props.onChangeEmailHandler}
                        onBlur={props.onBlurEmailHandler}
                        tabIndex={1}
                    />

                    {signupModel.isEmailValid ? '' : <label className="invalid-field">Email is invalid</label>}

                    <div className="business-radio-div">
                        <ASRadioGroup
                            radioInputs={radioGroup}
                            onChange={(event) =>
                                props.onCheckBusinessAccountHandler(event)
                            }
                        />
                    </div>

                    {!signupModel.isIndividual && (
                        <div className={"animated-div"}>
                            <ASTextField
                                className="text-field"
                                fullWidth
                                label="Business Name"
                                variant="outlined"
                                onChange={props.onChangeBusinessHandler}
                                onBlur={props.onBlurBusinessNameHandler}
                                tabIndex={2}
                            />
                            {signupModel.isBusinessNameValid ? '' : <label className="invalid-field">Business name is invalid</label>}
                        </div>
                    )}

                    <div>

                        <ASTextField
                            className="text-field"
                            type={signupModel.isPasswordVisible ? "text" : "password"}
                            fullWidth
                            label="Password"
                            variant="outlined"
                            onChange={props.onChangePasswordHandler}
                            onBlur={props.validatePassAndConfirmPass}
                            tabIndex={3}
                        />

                        <span className="eye-icon">
                            {
                                signupModel.isPasswordVisible ?
                                    <VisibilityOff onClick={props.showHidePassword} />
                                    :
                                    <VisibilityIcon onClick={props.showHidePassword} />
                            }
                        </span>

                    </div>

                    <div>

                        <ASTextField
                            className="text-field"
                            type={signupModel.isConfirmPasswordVisible ? "text" : "password"}
                            fullWidth
                            label="Confirm Password"
                            variant="outlined"
                            onChange={props.onChangeConfirmPassHandler}
                            onBlur={props.validatePassAndConfirmPass}
                            tabIndex={4}
                        />

                        <span className="eye-icon">
                            {
                                signupModel.isConfirmPasswordVisible ?
                                    <VisibilityOff onClick={props.showHideConfirmPassword} />
                                    :
                                    <VisibilityIcon onClick={props.showHideConfirmPassword} />


                            }
                        </span>

                    </div>

                    {!signupModel.isPasswordValidated &&
                        <label className="invalid-field">Password and confirm password must be same</label>}

                    <div className="login-button">

                        {/* <Link className="link" to={`/login`}> */}
                        <Button className="button" variant="contained" color="error"
                            onClick={props.createUser}                         >
                            Sign Up
                        </Button>
                        {/* </Link> */}
                    </div>

                </div>
            </form>

            {
                FormFooter(
                    `Sign Up With`,
                    `Already have an account? `,
                    "login", props
                )
            }

        </div >
    );
}

function forgotPassword(props) {
    const { state } = props;

    return (
        <div className="login-form-container">
            <form>

                <div className="login-form-inputs">

                    {FormHeader("Forgot Password", true)}
                    {
                        state.errorMessage &&
                        <MessageToaster errorMessage={state.errorMessage} />
                    }

                    <div className="forgot-password">

                        <ASTextField
                            className="text-field"
                            fullWidth
                            label="Email"
                            onBlur={props.onBlurEmailHandler}
                            onChange={props.onChangeEmailHandler}
                            variant="outlined"
                            tabIndex={0}
                        />
                        {state?.isEmailValid ? '' : <label className="invalid-field">Email is invalid</label>}

                    </div>

                    <div className="login-button login-buttons-group">

                        <Button className="button" variant="contained" color="error" onClick={props.handleSubmitForgetPassword} type="submit" disabled={props.buttonState}>
                            Send Reset Password Email
                        </Button>

                        {props.isEmailSent &&
                            <Button className="button" variant="contained" color="error" onClick={props.routeToLoginPage} type="submit">
                                Go to login page
                            </Button>
                        }



                    </div>

                </div>
            </form>

            {FormFooter(`Login With`, `Don't have an account? `, "signup", props)}

        </div>
    );
}

function resetPassword(props) {
    const { state } = props;

    return (
        <div className="login-form-container">
            <form>

                <div className="login-form-inputs">

                    {FormHeader("Reset Password", true)}
                    {
                        state.errorMessage &&
                        <MessageToaster
                            errorMessage={state.errorMessage}
                        />
                    }
                    <div>

                        <ASTextField
                            className="text-field"
                            type={state.isPasswordVisible ? "text" : "password"}
                            fullWidth
                            label="Password"
                            variant="outlined"
                            onChange={props.onChangePasswordHandler}
                            onBlur={props.validatePassAndConfirmPass}
                            tabIndex={3}
                        />

                        <span className="eye-icon">
                            {
                                state.isPasswordVisible ?
                                    <VisibilityOff onClick={props.showHidePassword} />
                                    :
                                    <VisibilityIcon onClick={props.showHidePassword} />
                            }
                        </span>

                    </div>

                    <div>

                        <ASTextField
                            className="text-field"
                            type={state.isConfirmPasswordVisible ? "text" : "password"}
                            fullWidth
                            label="Confirm Password"
                            variant="outlined"
                            onChange={props.onChangeConfirmPassHandler}
                            onBlur={props.validatePassAndConfirmPass}
                            tabIndex={4}
                        />

                        <span className="eye-icon">
                            {
                                state.isConfirmPasswordVisible ?
                                    <VisibilityOff onClick={props.showHideConfirmPassword} />
                                    :
                                    <VisibilityIcon onClick={props.showHideConfirmPassword} />
                            }
                        </span>

                        {!state.isPasswordValidated &&
                            <label className="invalid-field">Password and confirm password must be same</label>}

                    </div>

                    <div className="login-button">

                        <Button className="button" variant="contained" color="error" onClick={props.handleSubmitResetPassword} type="submit">
                            Reset Password
                        </Button>

                    </div>

                </div>
            </form>
        </div>
    );
}
