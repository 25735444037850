import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography
} from "@mui/material";
import React from "react";
import "./connection-type.scss";
import styles from "../../../styles/_exports.module.scss";

export function ConnectionType({
    usedCloudApplicationsId,
    selectedStep,
    selectedType,
    sourceType = [],
}) {

    return ( 
        <>
            <div className="connetion-type-label"> Natural Data sources </div>
            <div className="card-container-type">
                {sourceType.filter(card => card.templateConnectionId === null).map((card) => (

                    <Card
                        key={card.key}
                        sx={{
                            minWidth: "261px",
                            border: selectedStep && selectedStep.description === card.description
                                ? `2px solid ${styles.primaryColor}` : "",
                            boxShadow: selectedStep && selectedStep.description === card.description
                                ? "none !important" : "",
                        }}
                        className='connection-card'
                    >
                        <CardActionArea
                            onClick={() => selectedType(card)}
                            className='card-action-area'
                        >
                            {
                                card.iconImage &&
                                <CardMedia
                                    component="img"
                                    sx={{ width: "auto !important", height: '44px' }}
                                    // image={`/assets/${card.datasourceTypeIconImage}`}
                                    // alt={card.datasourceTypeIconImage}
                                    image={`/assets/${card.iconImage}`}
                                    alt={card.iconImage}
                                />
                            }

                            <CardContent className="connection-name">
                                <Typography gutterBottom variant="body2" component="div" sx={{ marginBottom: '0px' }}>
                                    {card.description}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </div>
            {sourceType.some(card => card.templateConnectionId !== null) && <div style={{ marginTop: "30px" }}>
                <div className="connetion-type-label"> Cloud Applications </div>
                <div className="card-container-type">
                    {sourceType.filter(card => card.templateConnectionId !== null).map((card) => (

                        <Card
                            key={card.key}
                            sx={{
                                minWidth: "261px",
                                border: selectedStep && selectedStep.description === card.description
                                    ? `2px solid ${styles.primaryColor}` : "",
                                boxShadow: selectedStep && selectedStep.description === card.description || usedCloudApplicationsId.has(card.templateConnectionId) 
                                    ? "none !important" : "",
                                backgroundColor:usedCloudApplicationsId.has(card.templateConnectionId)  
                                    ? `${styles.disabledColor}` : "",
                            }}
                            className='connection-card'
                            raised={usedCloudApplicationsId.has(card.templateConnectionId) ? false : true}
                        >
                            <CardActionArea
                                onClick={() => selectedType(card)}
                                className='card-action-area'
                                disabled={usedCloudApplicationsId.has(card.templateConnectionId)}
                            >
                                {
                                    card.iconImage &&
                                    <CardMedia
                                        component="img"
                                        sx={{ width: "auto !important", height: '44px' }}
                                        image={`/assets/${card.iconImage}`}
                                        alt={card.iconImage}
                                    />
                                }

                                <CardContent className="connection-name">
                                    <Typography gutterBottom variant="body2" component="div" sx={{ marginBottom: '0px' }}>
                                        {card.description}
                                        {usedCloudApplicationsId.has(card.templateConnectionId)?(<div style={{fontStyle:"italic"}}>
                                            connected
                                        </div>):null}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))}
                </div>
            </div>}

        </>
    );
}
