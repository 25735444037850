import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // you can add more states for different plugins
    isGoogleSheetPluginActive: false,
};

export const pluginSlice = createSlice({
    name: 'pluginState',
    initialState,
    reducers: {
        setIsGoogleSheetPluginActive: (state, action) => {
            state.isGoogleSheetPluginActive = action.payload;
        },
    },
});


export const { setIsGoogleSheetPluginActive } = pluginSlice.actions;

export default pluginSlice.reducer;
