import {
  Box,
  Stepper,
  Step,
  StepContent,
  styled,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  Paper,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { ASButton } from "../as-button/as-button";
import themeColor from "./../../styles/_exports.module.scss";
import "./as-stepper.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import {
  parameterTableHead,
  sysDataTypeList,
} from "../../components/data-collections/collection-parameters/collection-parameters";
import { AppEnum } from "../../constants/app-enum";
import { muiCheckboxStyle } from "../../components/user-permission/user-permission";
import TableHead from "@mui/material/TableHead";
import Checkbox from "@mui/material/Checkbox";
import { columnHeaderRowCells } from "../../controllers/data-collections/add-new-data-collection/select-columns/select-columns-controller";
import { tableBorderRight } from "../../controllers/data-collections/add-new-data-collection/add-new-data-collection";
import { ASSwitch } from "../as-switch/as-switch";
import { ASTextField } from "../as-text-fields";
import DataGridTable from "../../components/common/data-grid/data-grid";
import { Link } from "react-router-dom";
import React, { useEffect, useMemo, useRef } from "react";
import { columnFilterTableHead } from "../../components/data-collections/collection-filter-column/collection-filter-column";
import ReactJson from "react-json-view";
import { useASContext } from "../../controllers/context-api/as-context";
import { CollectionStepHeaderView } from "../../components/data-collections/collection-steps-view/collection-steps-view";

const ASStepLabel = styled(StepLabel)({
  "& .MuiStepLabel-label": {
    color: themeColor.mutedColor,
    marginTop: "2px",
    marginLeft: "3px",
    fontSize: `16px`,
  },
  "& .Mui-active,& .Mui-completed": {
    color: `${themeColor.secondaryColor} !important`,
    marginTop: "2px",
    marginLeft: "3px",
    fontWeight: `${themeColor.semiBold} !important`,
    fontSize: `16px`,
  },
});

const StepConnectorLine = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    marginLeft: "4px",
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, icon } = props;

  return (
    <div
      className={
        active || completed
          ? "activeOrCompleteState step-button"
          : "step-button"
      }
    >
      <label className={completed ? "completed-icon" : ""}>
        {completed ? <i>&#10003;</i> : icon}
      </label>
    </div>
  );
}

const optionalStepLabel = ({ image, label }) => {
  return (
    <div className="optionalLabelBlock">
      {image && <img src={`/assets/${image}`} alt="" />}
      {label}
    </div>
  );
};

// optional component for basic info section
const optionalStepLabels = (
  activeStep,
  { labels },
  isCollectionType,
  isCollectionAsDataSource,
  isCloudSteps
) => {
  return (
    <div className={`step-label-container ${activeStep == 0 ? "ml--21" : ""}`}>
      {labels?.map((label, index) => {
        return (
          <div className="step-label-div" key={index}>
            <label>{label.label}</label>
            <span
              className={
                isCollectionType && index === 1
                  ? "collection-type-step-label"
                  : ""
              }
            >
              {label.value}
            </span>
          </div>
        );
      })}
      {!isCollectionType && !isCloudSteps && (
        <div className="coll-as-conn">
          <Checkbox
            checked={isCollectionAsDataSource}
            sx={muiCheckboxStyle}
            disabled={true}
          />
          <label>Use Collection as Dynamic List Source</label>
        </div>
      )}
    </div>
  );
};

// optional component for displaying parameter table
const optionalStepParameter = (
  { collectionParameters, showSendAs },
  activeStep
) => {
  return (
    <div
      className={`step-parameter-container ${activeStep < 3 ? "ml--21" : ""}`}
    >
      <TableContainer sx={{ width: "1250px" }}>
        <Table sx={{ minWidth: 750 }} size="small" className="parameter-table">
          {parameterTableHead(true, showSendAs)}
          <TableBody>
            {collectionParameters?.map((ele, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    align="left"
                    component={"td"}
                    sx={tableBorderRight}
                  >
                    {ele?.parameterName}
                  </TableCell>

                  <TableCell
                    align="left"
                    component={"td"}
                    sx={tableBorderRight}
                  >
                    {ele?.displayName}
                  </TableCell>

                  <TableCell
                    align="left"
                    component={"td"}
                    sx={tableBorderRight}
                  >
                    {ele?.parameterTypeCD}
                  </TableCell>

                  {showSendAs && (
                    <TableCell
                      align="left"
                      component={"td"}
                      sx={tableBorderRight}
                    >
                      {ele?.sendAs}
                    </TableCell>
                  )}

                  <TableCell
                    align="left"
                    component={"td"}
                    sx={tableBorderRight}
                  >
                    {ele?.parameterTypeCD ===
                    AppEnum.ParameterTypeValue.UserDefinedVariable
                      ? sysDataTypeList.filter(
                          (i) => i.dataTypeId === Number(ele?.sysDataTypeId)
                        )[0]?.dataTypeName
                      : ele?.parameterTypeCD === AppEnum.ParameterTypeValue.UserInput
                      ? sysDataTypeList.filter(
                          (i) => i.dataTypeId === Number(ele?.inputFieldTypeCD)
                        )[0]?.dataTypeName
                      : ""}
                  </TableCell>

                  <TableCell align="left" component={"td"} sx={{wordBreak:"break-all"}}>
                    {ele?.defaultValue}{" "}
                    <span className="default-label">{`${
                      ele.isFixed ? " fixed" : " default"
                    }`}</span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

// optional component for column and formatting step
const optionalStepColumnAndFormatting = ({ collectionColumns,activeStep }) => {
  const isSelectedAll = collectionColumns?.every(column => column.isSelected);
  return (
    <div className={`step-label-container column-table-paper ${activeStep ? "" : "stepcolumn-end-margin"}`}>
      <TableContainer sx={{ width: "1250px", maxHeight: `calc(100vh - 240px)` }}>
        <Table sx={{ minWidth: 750 }} size="small" className="parameter-table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  color="primary"
                  disabled={true}
                  checked={isSelectedAll}
                  sx={muiCheckboxStyle}
                />
              </TableCell>

              {columnHeaderRowCells.slice(0, 4).map((headCell, index) => {
                return (
                  <TableCell
                    key={headCell.id}
                    align="left"
                    scope="row"
                    component={"th"}
                    sx={index > 2 ? { borderRight: "none" } : tableBorderRight}
                    className={index === 0 ? "name-cell" : ""}
                    id={`enhanced-table-checkbox-${index}`}
                    padding={index === 0 ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {collectionColumns?.map((column, index) => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={index}>
                  <TableCell
                    className={column?.isSelected ? "row-selected" : ""}
                  >
                    <Checkbox
                      color="primary"
                      checked={column?.isSelected}
                      disabled={true}
                      sx={muiCheckboxStyle}
                    />
                  </TableCell>

                  <TableCell
                    className={
                      column?.isSelected
                        ? "row-selected name-cell"
                        : "name-cell"
                    }
                    id={`columnCheckbox${index}`}
                    scope="row"
                    padding="none"
                    sx={tableBorderRight}
                  >
                    {column?.columnName
                      ? column?.columnName
                      : column?.ColumnName}
                  </TableCell>

                  <TableCell align="left" sx={tableBorderRight}>
                    {/* {column?.ColumnDataType} */}
                    {column?.ColumnDataType == 'Int64' ? 'Integer' : column?.ColumnDataType}
                  </TableCell>

                  <TableCell align="left" sx={tableBorderRight}>
                    {column?.displayFormat
                      ? column?.displayFormat
                      : column?.DisplayFormat}
                  </TableCell>

                  <TableCell align="left">
                    {column?.DisplayName
                      ? column?.DisplayName
                      : column?.displayName
                      ? column?.displayName
                      : column?.ColumnName}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const optionalStepTestData = (
  testedDataList,
  testedDataColumnKeys,
  returnRawData,
  activeStep
) => {
  return (
    <>
      <div className={`step-parameter-container testData`}>
        {
            testedDataList?.length > 0 && testedDataColumnKeys?.length > 0 && !returnRawData ? (
              <DataGridTable rows={testedDataList} columns={testedDataColumnKeys} />
            ) : (
              <div className={`text-items-left margin-3`}>
                <label>No data to preview</label>
              </div>
            )
          // )
        }
      </div>
    </>
  );
};

const optionalStepDataTransformationScript = (
  activeStep,
  restDataTransformationScript
) => {
  return (
    <div
      className={`step-parameter-container dataTransformationScript ${
        activeStep < 5 ? "ml--21" : ""
      }`}
    >
      <ASTextField
        value={restDataTransformationScript?.restDataTransformationScript}
        disabled={true}
        multiline
        rows={4}
      ></ASTextField>
    </div>
  );
};

const optionalStepDataAfterTransformation = (
  activeStep,
  testedDataTransformedList,
  testedDataTransformedColumneKeys,
  columnSchemaList,
  isShowHeader,
  showSendAsStep,
  collectionColumnSchema
) => {
  return (
    // (testedDataTransformedList.length > 0 && testedDataTransformedColumneKeys.length > 0) ?
    testedDataTransformedList.length > 0 &&
      testedDataTransformedColumneKeys.length > 0 ? (
      <>
        <div
          className={`test-step-container testData ${
            activeStep == 6 ? "test-stepper-alignment" : ""
          }`}
        >
          <TableContainer sx={{ overflowX: "unset" }}>
            <Table
              // sx={{ minWidth: 750 }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  {testedDataTransformedColumneKeys.map((column, index) => {
                    const columnSelection = columnSchemaList?.find(
                      (item) =>
                        (item?.columnName?.toLowerCase().trim() ||
                          item?.ColumnName?.toLowerCase().trim()) ===
                        column?.toLowerCase().trim()
                    );
                    return (
                      <TableCell
                        key={`${index}+${column}`}
                        align="left"
                        scope="row"
                        component={"th"}
                        sx={
                          testedDataTransformedColumneKeys?.length - 1 === index
                            ? { borderRight: "none" }
                            : tableBorderRight
                        }
                        id={`keys-${index}`}
                      >
                        <Checkbox
                          color="primary"
                          checked={columnSelection?.isSelected}
                          sx={{
                            ...muiCheckboxStyle,
                            paddingLeft: "0px",
                          }}
                          disabled={true}
                        />
                        {column}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {testedDataTransformedList?.map((column, index) => {
                  return (
                    <TableRow tabIndex={-1} key={`test-column${index}`}>
                      {testedDataTransformedColumneKeys.map((col, colIndex) => {
                        return (
                          <TableCell
                            key={`${colIndex}+keys`}
                            align={(column[col] !== undefined && column[col] !== null && !isNaN(parseFloat(column[col])) && isFinite(column[col])) ? 'right' : 'left'} 
                            scope="row"
                            component={"td"}
                            id={`keys-${colIndex}`}
                            sx={
                              testedDataTransformedColumneKeys?.length - 1 ===
                              colIndex
                                ? { borderRight: "none" }
                                : tableBorderRight
                            }
                          >
                            {/* // for accecssing dynamic props from object */}
                            {column[col] === null ? "" : String(column[col])}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    ) : isShowHeader && !showSendAsStep && collectionColumnSchema.length > 0 ? (
      <>
        <div className="test-step-container testData">
          <TableContainer sx={{ overflowX: "unset" }}>
            <Table
              // sx={{ minWidth: 750 }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  {collectionColumnSchema?.map((column, index) => {
                    const columnSelection = columnSchemaList?.find(
                      (item) =>
                        (item?.columnName?.toLowerCase().trim() ||
                          item?.ColumnName?.toLowerCase().trim()) ===
                        column?.columnName?.toLowerCase().trim()
                    );
                    return (
                      <TableCell
                        key={`${index}+${column?.columnName}`}
                        align="left"
                        scope="row"
                        component={"th"}
                        sx={
                          collectionColumnSchema?.length - 1 === index
                            ? { borderRight: "none" }
                            : tableBorderRight
                        }
                        id={`keys-${index}`}
                      >
                        <Checkbox
                          color="primary"
                          checked={columnSelection?.isSelected}
                          sx={{
                            ...muiCheckboxStyle,
                            paddingLeft: "0px",
                          }}
                          disabled={true}
                        />
                        {column?.columnName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody></TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    ) : (
      <div className={`text-items-left margin-3`}>
        <label>No data to preview</label>
      </div>
    )
  );
};

const optionalStepCollectionFilterColumn = (
  {collectionFilterColumns, showSendAs},
  
  activeStep
) => {
  return (
    <div
      className={`step-parameter-container ${activeStep < 3 ? "ml--21" : ""}`}
    >
      <TableContainer sx={{ width: "1250px" }}>
        <Table sx={{ minWidth: 750 }} size="small" className="parameter-table">
          {columnFilterTableHead(true, showSendAs)}
          <TableBody>
                    {collectionFilterColumns?.map((ele, index) => {
                      return (
                        <TableRow key={`collection-param ${index}`}>
                          <TableCell
                            align="left"
                            component={"td"}
                            sx={tableBorderRight}
                          >
                            {" "}
                            {ele?.columnName}{" "}
                          </TableCell>

                          <TableCell align="left" component={'td'} sx={tableBorderRight}>
                            {String(ele?.hasMultiSelection).charAt(0).toUpperCase() + String(ele?.hasMultiSelection).slice(1)}
                          </TableCell>

                          <TableCell align="left" component="td" sx={tableBorderRight}>
                            <Select
                              displayEmpty
                              value=""
                              renderValue={() => "Operators"}
                              disabled={ele?.operators?.length === 0}
                            >
                              {ele?.operators?.split(',').map((operator, idx) => (
                                <MenuItem key={idx} value={operator.trim()}>
                                  {operator.trim()}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>

                          <TableCell
                            align="left"
                            component={"td"}
                            sx={tableBorderRight}
                          >
                            {" "}
                            {ele?.variableName}{" "}
                          </TableCell>
                          
                          
                        </TableRow>
                     );
                    })}
            </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export const ASStepperView = (props) => {
  const {
    steps,
    showSendAsStep,
    activeStep,
    handleBack,
    handleSave,
    handleNext,
    isTestingConnection,
    uId,
    deleteConnection,
    isConnection,
    updateActiveStatus,
    activeStatus,
    isTemplateCollection,
    isSavingCollection,
    stepperViewFromConnection,
    isShowHeader,
    handleStepClick,
    getButtonWidth,
    stepRefs,
    scrollToActiveStep,
    isStepVisible,
    isCloudSteps,
    isTestedData
  } = props;

  useEffect(() => {
    setTimeout(()=>{
      scrollToActiveStep();
    },600)

  }, [activeStep]);

  const asContext = useASContext();

  const isBackDisabled = useMemo(() => {
    const stepAfterColumnSelectionStep = asContext.templateCompany.isTemplateCompany ? steps?.length - 2 : steps?.length - 1 ;
    return (stepAfterColumnSelectionStep == activeStep && !stepperViewFromConnection && !isTestedData && !isCloudSteps);
  }, [steps?.length, activeStep, stepperViewFromConnection, isTestedData, isCloudSteps]);

  return (
    <Box sx={{ maxWidth: "100%" }}>

      {/* { (showSaveCollection && !activeStepDetails?.disabled) &&
        <div className="save-changes-button-div">
          <ASButton primary={true} onClick={() => handleSaveChangesWithPopupedButton()}>Save Changes</ASButton>
        </div>
      } */}
       {(!stepperViewFromConnection && uId) && <CollectionStepHeaderView
            steps={steps}
            activeStep={activeStep}
            handleStepClick={handleStepClick}
        />}
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<StepConnectorLine />}
      >
        {steps.map((step, index) => {
          const isShowStep = isStepVisible(index);
          return (
            isShowStep && <Step
              key={step.label}
              id={`muiStepper${index}`}
              ref={stepRefs.current[index]}
            >
              <ASStepLabel
                sx={{ alignItems: "inherit" }}
                StepIconComponent={ColorlibStepIcon}
                optional={
                  step?.isCollectionType && index !== activeStep
                    ? optionalStepLabels(
                        activeStep,
                        step.optionalComponent,
                        step?.isCollectionType
                      )
                    : step?.isBasicInfo && index !== activeStep
                    ? optionalStepLabels(
                        activeStep,
                        step.optionalComponent,
                        step?.isCollectionType,
                        step?.isCollectionAsDataSource,
                        isCloudSteps
                      )
                    : step?.isParameterSubmitted && index !== activeStep
                    ? optionalStepParameter(step.optionalComponent, activeStep)
                    : step?.isColumnAndFormatSubmitted && index !== activeStep
                    ? optionalStepColumnAndFormatting(step.optionalComponent,activeStep)
                    : step?.isDataFormationStep &&
                      index !== activeStep &&
                      step.optionalComponent?.restDataTransformationScript
                    ? optionalStepDataTransformationScript(
                        activeStep,
                        step.optionalComponent
                      )
                    : step?.isTestedData &&
                      index !== activeStep &&
                      step.optionalComponent?.testedDataList
                    ? optionalStepTestData(
                        step.optionalComponent?.testedDataList,
                        step.optionalComponent?.testedDataColumnKeys,
                        step.optionalComponent?.returnRawData,
                        activeStep
                      )
                    : step?.isDataAfterFormatingStep &&
                      index !== activeStep &&
                      step.optionalComponent?.testedDataTransformedList
                    ? optionalStepDataAfterTransformation(
                        activeStep,
                        step.optionalComponent?.testedDataTransformedList,
                        step.optionalComponent
                          ?.testedDataTransformedColumneKeys,
                        step.optionalComponent?.columnSchemaList,
                        isShowHeader,
                        showSendAsStep,
                        step.optionalComponent?.collectionColumnSchema
                      )
                      : step?.isCollectionFilterColumnSubmitted && 
                      index !== activeStep ?
                      optionalStepCollectionFilterColumn(step.optionalComponent, activeStep)
                    : index !== activeStep && step.optionalComponent
                    ? optionalStepLabel(step.optionalComponent)
                    : null
                }
              >
                <div className="label-button-div">
                  <Link
                    onClick={() => handleStepClick(step.label)}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {step.label}
                  </Link>
                  <div style={{ display: "flex", columnGap: "15px" }}>
                    {(step.isShowDeleteButton ||
                      (uId && step.label === "Select Connection")) && (
                      <div>
                        <ASSwitch
                          checked={activeStatus}
                          onChange={(e) => updateActiveStatus(e)}
                        />
                        <span className="switch-btn-text">Active</span>
                      </div>
                    )}
                    {step.isShowDeleteButton && (
                      <ASButton
                        variant="contained"
                        sx={{ mr: 1 }}
                        primary={true}
                        onClick={() => deleteConnection()}
                      >
                        Delete
                      </ASButton>
                    )}
                  </div>
                </div>
              </ASStepLabel>

              <StepContent
                sx={{
                  maxWidth: getButtonWidth(step),

                  marginLeft: "16px",
                  paddingLeft: "26px",
                }}
              >
                {step.description}

                <Box sx={{ mb: 2 }}>
                  <div className="button-box">
                    <ASButton
                      variant="contained"
                      disabled={step.disabledBack || isTestingConnection || isBackDisabled}
                      onClick={() => {
                        handleBack();
                        // scrollToPreviousLabelRefStep()
                      }}
                      sx={{
                        mr: 1,
                        marginRight: "25px",
                        visibility: index === 0 ? "hidden" : "visible",
                      }}
                    >
                      BACK
                    </ASButton>

                    {index === steps.length - 1 ? (
                      <ASButton
                        variant="contained"
                        onClick={handleSave}
                        disabled={
                          step.disabled ||
                          isTestingConnection ||
                          isTemplateCollection ||
                          isSavingCollection ||
                          (showSendAsStep && isShowHeader) ||
                          (stepperViewFromConnection &&
                            !(
                              step.description.props?.formData?.name?.value?.trim()
                                .length > 0
                            ))
                        }
                        sx={{ mr: 1 }}
                        primary={true}
                      >
                        {uId ? "Update" : "Save"}
                      </ASButton>
                    ) : (
                      <ASButton
                        variant="contained"
                        onClick={()=>{
                            handleNext() 
                        }}
                        disabled={step.disabled || isTestingConnection}
                        sx={{ mr: 1, marginRight: "0px" }}
                        primary={true}
                      >
                        NEXT
                      </ASButton>
                    )}
                  </div>
                </Box>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
